import React, { useState, useEffect, createContext } from 'react';
import 'antd/dist/antd.css';
import '../styles/global.css';
import { RouteConfig } from '../config/route';
import { BrowserRouter as Router, Route, useLocation, withRouter } from 'react-router-dom';
import HeaderComp from '../components/HeaderComp';
import FooterComp from '../components/FooterComp';
import { Layout, Spin } from 'antd';
import "../styles/banner.css";
import MenuComp from "../components/MenuComp";
import NavbarComp from "../components/NavbarComp";

function HandleScrollToTop(props){
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [ pathname ]);

  return props.children;
}

const ScrollToTop = withRouter(HandleScrollToTop);

export const LoadingContext = createContext();

function App() {
  // mobile navbar visibility control
  const [ visible, setVisible ] = useState(false);

  // let ant-layout move while mobile navbar is active
  const [ isActive, setActive ] = useState(visible);
  const handleToggle = () => {
    setActive(!isActive);
  };

  // loading controll
  const [ isLoading, setIsLoading ] = useState(false);
  const handleLoading = () => {
    setIsLoading(true);
  }

  const handleNotLoading = () => {
    setIsLoading(false);
  }

  return (
    <LoadingContext.Provider value={{ isLoading, handleLoading, handleNotLoading }}>
      <Router>
        <ScrollToTop>
          <Spin tip="Loading..." spinning={ isLoading }>
            <Layout className={ isActive ? "move" : null }>
              <HeaderComp 
                handleToggle={ handleToggle }
                visible={ visible }
                setVisible={ setVisible }
              />
              <NavbarComp menu={ <MenuComp /> } />
              {
                RouteConfig.map(( item, index ) => (
                  <Route 
                    key={ index } 
                    path={ item.path } 
                    exact={ item.exact } 
                    component={ item.component }
                  />
                ))
              }
              <FooterComp />
            </Layout>
          </Spin>
        </ScrollToTop>
      </Router>
    </LoadingContext.Provider>
  );
}

export default App;