import { Link } from 'react-router-dom';
import Aupost from '../img/Aupost.jpg'
import Colissimo from '../img/colissimo.jpg'
import Correos from '../img/correos.jpg'
import deutschePost from '../img/deutsche-post.jpg'
import DHL_C from '../img/DHL-C.jpg'
import DPB from '../img/dpd.jpg'
import Fastway from '../img/fastway.jpg'
import Fedex_C from '../img/fedex-C.jpg'
import Hermes_C from '../img/hermes-C.jpg'
import royalMail_C from '../img/royal-mail-C.jpg'
import TNT from '../img/TNT.jpg'
import UPS_C from '../img/UPS-C.jpg'
import USPS from '../img/USPS.jpg'
import Amazon_C from '../img/amazon-C.jpg'
import Cdiscount from '../img/Cdiscount.jpg'
import Ebay_C from '../img/ebay-C.jpg'
import Facebook from '../img/facebook.jpg'
import Indiegogo from '../img/indiegogo.jpg'
import Kicksrarter from '../img/kicksrarter.jpg'
import newEgg from '../img/new-egg.jpg'
import Rakuten from '../img/rakuten.jpg'
import Walmart from '../img/walmart.jpg'
import Wish from '../img/wish.jpg'

export default function PartnerImages() {
  const PartnerImages = [
    Aupost,
    Colissimo,
    Correos,
    deutschePost,
    DHL_C,
    DPB,
    Fastway,
    Fedex_C,
    Hermes_C,
    royalMail_C,
    TNT,
    UPS_C,
    USPS,
    Amazon_C,
    Cdiscount,
    Ebay_C,
    Facebook,
    Indiegogo,
    Kicksrarter,
    newEgg,
    Rakuten,
    Walmart,
    Wish
  ]

  return PartnerImages.map((img, index) => (
    <div className='partner-image' key={index}>
      <div className='gallery-list media-image'>
        <Link to="/partners">
          <img src={img} width={150} height={150} alt=''/>
        </Link>
      </div>
    </div>
  ))
}