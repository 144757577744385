import React from 'react';
import img1 from '../../img/Blog_img_8_1.jpeg';
import img2 from '../../img/Blog_img_8_2.png';
import img3 from '../../img/Blog_img_8_3.png';
import { useTranslation } from 'react-i18next';

const Blog8 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '你有曾經想過用無人機送上早晨的咖啡和熱騰騰的可頌，而不是在星巴克排隊等候嗎?如果你住在澳洲北坎培拉(North Canberra)，你將有機會享有這樣的服務!',
    'text2': 
      '本週，Google 姊妹公司Wing 宣布他們將在澳洲首都坎培拉(Canberra)推出有限的商業運送服務，如果一切發展順利，Google將新增一些可運送的商品和擴大運送地區。消費者可以在Wing的手機應用程式中購物，付款後，商品就會以無人機運送。根據Wing表示，在測試期間包含生鮮、咖啡、冰淇淋、未需處方的成藥等等。Wing也將繼續和不同商家合作，運送商家的商品。',
    'text3': 
      'Google姊妹公司Wing自2014年以來一直在澳洲測試無人機運送服務，但本週的正式宣布正式推出此服務，代表著一個重要門檻就是向世界公開。雖然它不是世界第一個推出的公司，早在2017年冰島的一家公司早就開始無人機運送服務，但Google在澳洲獲得授權開始提供消費者服務仍然是一個重大的突破。',
    'text4': 
      'Business Insider指出，無人機運送服務的推出伴隨著各種不同的規定，包括無人機可以在哪裡飛行以及無人機可運送的時間，而且並非沒有任何限制。再者，在坎培拉進行的測試顯示，無人駕駛飛機並不是完全安靜的，居住在其中一家商店附近的鄰居就曾抱怨飛機前後不停的吵雜聲。其中，Wing的代表表示，他們正致力於開發更安靜的無人機。',
    'text5': 
      '雖然Wing獲得澳洲民航安全局(Civil Aviation Safety Authority)的許可證，但根據澳洲民航安全局規定，外送無人機不能飛在主要幹道上、得維持人們5公尺以上高度飛行，外送時，也得離人們2公尺高，再將物品降落家戶前。外送無人機在週間早上7點之前、週日早上8點之前，也不准飛行。',
    'text6': 
      'Wing去年12月也已經在芬蘭的首都赫爾辛基(Helsinki Helsingfors)推出試驗服務，下一個瞄準歐洲市場。除此之外，Uber打算在未來兩年內實現無人機送餐，目標要在2021年或提前實現UberExpress的無人機運送服務。Uber CEO更認為，無人機運送服務有助於解決都市交通壅塞的問題，目標要在5-30分鐘內送達。',
  };

  const text_cn = {
    'text1': 
      '你有曾经想过用无人机送上早晨的咖啡和热腾腾的可颂，而不是在星巴克排队等候吗?如果你住在澳洲北坎培拉(North Canberra)，你将有机会享有这样的服务!',
    'text2': 
      '本周，Google 姊妹公司Wing 宣布他们将在澳洲首都坎培拉(Canberra)推出有限的商业运送服务，如果一切发展顺利，Google将新增一些可运送的商品和扩大运送地区。消费者可以在Wing的手机应用程式中购物，付款后，商品就会以无人机运送。根据Wing表示，在测试期间包含生鲜、咖啡、冰淇淋、未需处方的成药等等。 Wing也将继续和不同商家合作，运送商家的商品。',
    'text3': 
      'Google姊妹公司Wing自2014年以来一直在澳洲测试无人机运送服务，但本周的正式宣布正式推出此服务，代表着一个重要门槛就是向世界公开。虽然它不是世界第一个推出的公司，早在2017年冰岛的一家公司早就开始无人机运送服务，但Google在澳洲获得授权开始提供消费者服务仍然是一个重大的突破。',
    'text4': 
      'Business Insider指出，无人机运送服务的推出伴随着各种不同的规定，包括无人机可以在哪里飞行以及无人机可运送的时间，而且并非没有任何限制。再者，在坎培拉进行的测试显示，无人驾驶飞机并不是完全安静的，居住在其中一家商店附近的邻居就曾抱怨飞机前后不停的吵杂声。其中，Wing的代表表示，他们正致力于开发更安静的无人机。',
    'text5': 
      '虽然Wing获得澳洲民航安全局(Civil Aviation Safety Authority)的许可证，但根据澳洲民航安全局规定，外送无人机不能飞在主要干道上、得维持人们5公尺以上高度飞行，外送时，也得离人们2公尺高，再将物品降落家户前。外送无人机在周间早上7点之前、周日早上8点之前，也不准飞行。',
    'text6': 
      'Wing去年12月也已经在芬兰的首都赫尔辛基(Helsinki Helsingfors)推出试验服务，下一个瞄准欧洲市场。除此之外，Uber打算在未来两年内实现无人机送餐，目标要在2021年或提前实现UberExpress的无人机运送服务。 Uber CEO更认为，无人机运送服务有助于解决都市交通壅塞的问题，目标要在5-30分钟内送达。',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <p>
        { langTW && text_tw.text6 }
        { langCN && text_cn.text6 }
      </p>
      <img src={ img3 } alt="img3"/>
    </>
  )
}

export default Blog8;