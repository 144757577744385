import React from 'react';
import img1 from '../../img/News_img_5_1.jpg';
import img2 from '../../img/News_img_5_2.jpg';
import img3 from '../../img/News_img_5_3.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News5 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '唯高盛集團於2月28日正式成立！感謝各位來賓的參與，見證香港首間跨境電子商務服務供應商的誕生。唯高盛 唯新電貿 高峰共創 盛勢將臨',
    'text2':
      '集團展望其後作為領航者，帶領團隊為香港產業尋找一片新天地！',
  };

  const text_cn = {
    'text1': 
      '唯高盛集团于2月28日正式成立！感谢各位来宾的参与，见证香港首间跨境电子商务服务供应商的诞生。唯高盛 唯新电贸 高峰共创 盛势将临',
    'text2':
      '集团展望其后作为领航者，带领团队为香港产业寻找一片新天地！',
  };

  const text_en = {
    'text1': 
      'Cross Border E-commerce Service (CBES) Group is established on 28 Feburary 2019! We wish to express our sincere gratitude to all the guests who have come and witnessed the rise of the first and the only multi-dimensional e-Commerce service provider in Hong Kong.',
    'text2':
      'We envision to be the pioneer by bringing in new horizons in the industry, and create a new era of the e-Commerce history together!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img2 } alt="img2"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img3 } alt="img3"/>
        </Col>
      </Row>
    </>
  )
}

export default News5;