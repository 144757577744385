import React from 'react';
import img1 from '../../img/News_img_2_1.jpg';
import { useTranslation } from 'react-i18next';

const News2 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '感謝在過去一直使用我們的物流服務，Continental全體同仁謝謝大家過去的支持和寶貴的建議，祝福大家來年好運，並在電商上取得更大成就！',
    'text2':
      '請大家留意2月份全球假期，新年時的香港、台灣和中國辦公室以及倉庫將會休假，其他國家服務維持正常。敬請留意。',
    'text3':
      '敬祝各位豬年行大運，新年快樂!'
  };

  const text_cn = {
    'text1': 
      '感谢在过去一直使用我们的物流服务，Continental全体同仁谢谢大家过去的支持和宝贵的建议，祝福大家来年好运，并在电商上取得更大成就！',
    'text2':
      '请大家留意2月份全球假期，新年时的香港、台湾和中国办公室以及仓库将会休假，其他国家服务维持正常。敬请留意。',
    'text3':
      '敬祝各位猪年行大运，新年快乐!'
  };

  const text_en = {
    'text1': 
      'Thank you for choosing us as your logistic partner, we can’t be here without all of you. Good luck and great success on E-Commerce in the coming Lunar New Year!',
    'text2':
      'Please note February’s Global Holiday. Hong Kong ,Taiwan and China officesand warehouses will be closed during the lunar new year but services in other countries will remain normal. Thank you for your attention.',
    'text3':
      'Best wishes for the Year of Pig! Happy Lunar New Year!'
  };

  return(
    <>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
        { langEN && text_en.text3 }
      </p>
      <img src={ img1 } alt="img1"/>
    </>
  )
}

export default News2;