import React from 'react';
import { Layout } from 'antd';
import '../styles/footerComp.css';
import logoImg from '../img/CBES_Logo.svg';
import FbImg from '../img/FB.svg';
import IgImg from '../img/IG.svg';
import InImg from '../img/IN.svg';
import EmailIcon from '../img/email_icon.jpg';
import ArrowIcon from '../img/arrow_icon.jpg';
import { Link } from 'react-router-dom';
import banner_about from '../img/banner_about.jpg';
import banner_news from '../img/banner_news.jpg';
import banner_service from '../img/banner_service.jpg';
import banner_blog from '../img/banner_blog.jpg';
import banner_partners from '../img/banner_partners.jpg';
import banner_contact from '../img/banner_contact.jpg';
import { useTranslation } from 'react-i18next';

const { Footer } = Layout;

const FooterComp = () => {
  const { t, i18n } = useTranslation();

  return(
    <>
      <Layout>
        <Footer className="footerComp">
          <div className="footerContainer">
            <div className="wrap">
              <ul className="content">
                <li>
                  <img width="227.5px" height="79px" src={ logoImg } alt="cbes_logo"/>
                  <p className="intro">
                    { t('footer.intro') }
                  </p>
                </li>
                <li>
                  <ul className="menu_link">
                    <li>
                      <Link 
                        to={{
                          pathname: "/aboutUs",
                          state:{ 
                            pageTitle: "關於集團", 
                            bannerImg: banner_about, 
                            bannerAlt: "banner-about-img"
                          }
                        }} 
                      >
                        { t('page.title.aboutUs') }
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to={{
                          pathname: "/ourCeo",
                          state:{ 
                            pageTitle: "關於總裁", 
                            bannerImg: banner_about, 
                            bannerAlt: "banner-about-img"
                          }
                        }} 
                      >
                        { t('page.title.ourCeo') }
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to={{
                          pathname: "/ourTeam",
                          state:{ 
                            pageTitle: "集團菁英", 
                            bannerImg: banner_about, 
                            bannerAlt: "banner-about-img"
                          }
                        }} 
                      >
                        { t('page.title.ourTeam') }
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to={{
                          pathname: "/news",
                          state:{ 
                            pageTitle: "最新消息", 
                            bannerImg: banner_news, 
                            bannerAlt: "banner-news-img"
                          }
                        }} 
                      >
                        { t('page.title.news') }
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className="menu_link">
                    <li>
                      <Link 
                        to={{
                          pathname: "/service",
                          state:{ 
                            pageTitle: "核心業務", 
                            bannerImg: banner_service, 
                            bannerAlt: "banner-service-img"
                          }
                        }} 
                      >
                        { t('page.title.service') }
                      </Link>
                    </li>
                    {
                      i18n.language === "en"?
                      null :
                      <li>
                        <Link 
                          to={{
                            pathname: "/blog",
                            state:{ 
                              pageTitle: "電商日誌", 
                              bannerImg: banner_blog, 
                              bannerAlt: "banner-blog-img"
                            }
                          }} 
                        >
                          { t('page.title.blog') }
                        </Link>
                      </li>
                    }
                    <li>
                      <Link 
                        to={{
                          pathname: "/partners",
                          state:{ 
                            pageTitle: "合作夥伴", 
                            bannerImg: banner_partners, 
                            bannerAlt: "banner-partners-img"
                          }
                        }} 
                      >
                        { t('page.title.partners') }
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to={{
                          pathname: "/contact",
                          state:{ 
                            pageTitle: "合作夥伴", 
                            bannerImg: banner_contact, 
                            bannerAlt: "banner-contact-img"
                          }
                        }} 
                      >
                        { t('page.title.contact') }
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="contact_info">
                    <img className="email" src={ EmailIcon } alt="email_icon"/>
                    info@cbesg.com
                  </p>
                  <p className="contact_info">
                    <img className="phone" src={ ArrowIcon } alt="arrow_icon"/>
                    { t('footer.hk.phone') }
                  </p>
                  <p className="contact_info">
                    <img className="phone" src={ ArrowIcon } alt="arrow_icon"/>
                    { t('footer.tw.phone') }
                  </p>
                  <ul className="social_media">
                    <li>
                      <a href="https://www.facebook.com/cbesgroup/">
                        <img width="25px" src={ FbImg } alt="fb_img"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/cbesgroup/">
                        <img width="25px" src={ IgImg } alt="ig_img"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/cbesg/">
                        <img width="25px" src={ InImg } alt="in_img"/>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p className="copyright">Copyright © 2021 CBES. All Right Reserved.</p>
        </Footer>
      </Layout>
    </>
  )
}

export default FooterComp;
