import React from 'react';
import img1 from '../../img/Blog_img_5_1.jpg';
import img2 from '../../img/Blog_img_5_2.jpg';
import img3 from '../../img/Blog_img_5_3.jpg';
import { useTranslation } from 'react-i18next';

const Blog5 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '近年各大品牌執行網路行銷操作，許多網路公司常常會協助電商品牌客戶執行 KOL 合作。但是想找KOL合作必須先了解什麼是KOL!',
    'text2': 
      'KOL，英文Key Opinion Leader的首字母縮寫，直接翻譯為「關鍵意見領袖」，是伴隨著社交媒體發展起來的新品牌代言人。隨著消費者的注意力質量越來越高，KOL成為品牌吸引目光的手段之一。但是KOL跟網紅有什麼不同之處呢? KOL在社交媒體環境下，對於傳遞品牌的訊息至關重要，KOL不能簡單等於藝人代言和網紅。已有聲量的網絡紅人、藝人、企業主、員工、同行對手、專家等等，都可能成為品牌的意見領袖。在一定的環境和機遇下，普通消費者其實也能成為KOL的! 但是KOL更需要有比較專業的知識，他的粉絲群體有一定特殊屬性，並對他有一定的信任度，反之網紅更著重於娛樂性質。',
    'text3': 
      '那要如何成功挑選適合自己品牌的KOL呢?以下整理了5個小秘訣',
    'text4': 
      '當下最有聲量的KOL不一定就是最好的選擇',
    'text5': 
      'FB粉絲團和Instagram專頁裡的互動率和粉絲的黏著度',
    'text6': 
      '若是新品牌建議先從素人或大眾化的KOL著手',
    'text7': 
      '你所需要銷售的產品和KOL的粉絲是否有關連',
    'text8': 
      '觀察篩選後的KOL名單處於正在成長還是已經到了成熟階段',
    'text9': 
      '首先第一點觀最紅的KOL不一定是最好的選擇。品牌必須先觀察KOL的粉絲是屬於哪種類型的，判斷他們的年齡。從互動中判斷及評估粉絲會不會買此類的商品，要以最能將商品特色發揮出來的KOL為首要的選擇。',
    'text10': 
      '第二，判斷粉絲的黏著度。千萬不能被粉絲團或追蹤人數所迷思，尤其要先看看KOL的業配的文章中與粉絲的互動。雖然粉絲數或追蹤人數多，但是可能許多都是潛水型的粉絲，並不會參與互動的貼文。',
    'text11': 
      '第三，如果是新品牌從素人或大眾化的KOL下手。首要的規劃以素人的體驗，先從商品互惠或小額預算來執行合作，從大量的名單結果中就能更清楚以後文章調整的方向。',
    'text12': 
      '第四，銷售的產品和KOL的粉絲是否有關連。需銷售的商品屬性與 KOL 的形象及粉絲相對應，不僅能提升品牌形象，也能得到不同的效果。',
    'text13': 
      '最後第五，觀察篩選後的KOL處於階段。如果考慮成本，處於成長期的KOL更適合，廣告合作與粉絲增長可以配合完成；而處於成熟期的KOL，廣告投放性價比可能沒有這麼明顯。',
    'text14': 
      '在現今人人都是KOL的時代，不管你是品牌在尋找適合的自己的KOL，還是想成為KOL等著品牌來尋覓的你，都是需要持續性的努力和操作!',
  };

  const text_cn = {
    'text1': 
      '近年各大品牌执行网路行销操作，许多网路公司常常会协助电商品牌客户执行 KOL 合作。但是想找KOL合作必须先了解什么是KOL!',
    'text2': 
      'KOL，英文Key Opinion Leader的首字母缩写，直接翻译为「关键意见领袖」，是伴随着社交媒体发展起来的新品牌代言人。随着消费者的注意力质量越来越高，KOL成为品牌吸引目光的手段之一。但是KOL跟网红有什么不同之处呢? KOL在社交媒体环境下，对于传递品牌的讯息至关重要，KOL不能简单等于艺人代言和网红。已有声量的网络红人、艺人、企业主、员工、同行对手、专家等等，都可能成为品牌的意见领袖。在一定的环境和机遇下，普通消费者其实也能成为KOL的! 但是KOL更需要有比较专业的知识，他的粉丝群体有一定特殊属性，并对他有一定的信任度，反之网红更着重于娱乐性质。',
    'text3': 
      '那要如何成功挑选适合自己品牌的KOL呢?以下整理了5个小秘诀',
    'text4': 
      '当下最有声量的KOL不一定就是最好的选择',
    'text5': 
      'FB粉丝团和Instagram专页里的互动率和粉丝的黏着度',
    'text6': 
      '若是新品牌建议先从素人或大众化的KOL着手',
    'text7': 
      '你所需要销售的产品和KOL的粉丝是否有关连',
    'text8': 
      '观察筛选后的KOL名单处于正在成长还是已经到了成熟阶段',
    'text9': 
      '首先第一点观最红的KOL不一定是最好的选择。品牌必须先观察KOL的粉丝是属于哪种类型的，判断他们的年龄。从互动中判断及评估粉丝会不会买此类的商品，要以最能将商品特色发挥出来的KOL为首要的选择。',
    'text10': 
      '第二，判断粉丝的黏着度。千万不能被粉丝团或追踪人数所迷思，尤其要先看看KOL的业配的文章中与粉丝的互动。虽然粉丝数或追踪人数多，但是可能许多都是潜水型的粉丝，并不会参与互动的贴文。',
    'text11': 
      '第三，如果是新品牌从素人或大众化的KOL下手。首要的规划以素人的体验，先从商品互惠或小额预算来执行合作，从大量的名单结果中就能更清楚以后文章调整的方向。',
    'text12': 
      '第四，销售的产品和KOL的粉丝是否有关连。需销售的商品属性与 KOL 的形象及粉丝相对应，不仅能提升品牌形象，也能得到不同的效果。',
    'text13': 
      '最后第五，观察筛选后的KOL处于阶段。如果考虑成本，处于成长期的KOL更适合，广告合作与粉丝增长可以配合完成；而处于成熟期的KOL，广告投放性价比可能没有这么明显。',
    'text14': 
      '在现今人人都是KOL的时代，不管你是品牌在寻找适合的自己的KOL，还是想成为KOL等着品牌来寻觅的你，都是需要持续性的努力和操作!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <ul>
        <li>
          { langTW && text_tw.text4 }
          { langCN && text_cn.text4 }
        </li>
        <li>
          { langTW && text_tw.text5 }
          { langCN && text_cn.text5 }
        </li>
        <li>
          { langTW && text_tw.text6 }
          { langCN && text_cn.text6 }
        </li>
        <li>
          { langTW && text_tw.text7 }
          { langCN && text_cn.text7 }
        </li>
        <li>
          { langTW && text_tw.text8 }
          { langCN && text_cn.text8 }
        </li>
      </ul>
      <p>
        { langTW && text_tw.text9 }
        { langCN && text_cn.text9 }
      </p>
      <p>
        { langTW && text_tw.text10 }
        { langCN && text_cn.text10 }
      </p>
      <p>
        { langTW && text_tw.text11 }
        { langCN && text_cn.text11 }
      </p>
      <p>
        { langTW && text_tw.text12 }
        { langCN && text_cn.text12 }
      </p>
      <p>
        { langTW && text_tw.text13 }
        { langCN && text_cn.text13 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text14 }
        { langCN && text_cn.text14 }
      </p>
    </>
  )
}

export default Blog5;