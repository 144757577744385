import React, { useState, useContext } from 'react';
import MetaTags from 'react-meta-tags';
import { Layout, Form, Input, Button, message } from 'antd';
import '../styles/contact.css';
import Banner from "../components/Banner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { config } from '../config/token';
import ReCAPTCHA from 'react-google-recaptcha';
import { LoadingContext } from '../components/_App';

const { Content } = Layout;

function Contact(props) {
  const loadingContext = useContext(LoadingContext);
  const [ recaptchaToken, setRecaptchaToken ] = useState('');

  const validateMessages = {
    required: '此為必填欄位!',
    types: {
      email: '不是正確的信箱格式!'
    }
  };

  const onChange = value => {
    // console.log('Captcha value:', value);
    setRecaptchaToken(value);
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': `${ config.token }`
  }

  const [form] = Form.useForm();

  const onFinish = ( values ) => {
    values = { ...values, type: "contact" };
    console.log(values);
    loadingContext.handleLoading();
    axios.post('/api/send', { 'data': values }, { headers: headers })
    .then(res => {
      console.log(res);
      if(res.status === 200){
        if(!recaptchaToken || recaptchaToken === undefined){
          recaptchaErrorMsg();
          loadingContext.handleNotLoading();
        } else {
          successMsg();
          loadingContext.handleNotLoading();
          onReset();
          window.grecaptcha.reset();
        }
      }
    })
    .catch(error => {
      console.log(error);
      console.log(recaptchaToken);
      if(!recaptchaToken || recaptchaToken === undefined){
        recaptchaErrorMsg();
        loadingContext.handleNotLoading();
      } else {
        errorMsg();
        loadingContext.handleNotLoading();
        onReset();
        window.grecaptcha.reset();
      }
    })
  };

  const onReset = () => {
    form.resetFields();
  };

  const successMsg = () => {
    message.success('留言發送成功！');
    // .then(() => window.location.reload());
  };

  const errorMsg = () => {
    message.error('留言發送失敗！');
    // .then(() => window.location.reload());
  };

  const recaptchaErrorMsg = () => {
    message.error('請勾選 Recaptcha 核取方塊');
  }

  const { t } = useTranslation();

  return(
    <>
      <MetaTags>
        <title>聯絡我們 | CBES 唯高盛集團</title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content style={{backgroundColor: '#f5f5f5'}}>
        <div className="wrap">
          <div className="contact_content">
            <div className="contact_form">
              <h2>
                { t('contact.contactForm.title') }
              </h2>
              <div className="divider_container">
                <div className="divider"></div>
              </div>
              <Form name="contact" onFinish={ onFinish } validateMessages={ validateMessages } form={form}>
                <Form.Item 
                  name={['name']} 
                  rules={[{ required: true }]}
                  label={ t('contact.contactForm.name') }
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item 
                  name={['email']} 
                  label="Email" 
                  rules={[{ type: 'email', required: true }]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item 
                  name={['subject']} 
                  rules={[{ required: true }]}
                  label={ t('contact.contactForm.subject') }
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item 
                  name={['content']} 
                  rules={[{ required: true }]}
                  label={ t('contact.contactForm.content') }
                  className="contact_content_label"
                >
                  <Input.TextArea rows={4} height="120px" />
                </Form.Item>

                <ReCAPTCHA
                  sitekey={`${config.recaptcha_sitekey}`}
                  onChange={ onChange }
                />

                <Button type="primary" htmlType="submit" className="submit_btn" loading={loadingContext.isLoading}>
                  { 
                    loadingContext.isLoading ? 
                    'Loading' :
                    t('contact.contactForm.submit') 
                  }
                </Button>
              </Form>
            </div>
            <div className="contact_info">
              <h2>
                { t('contact.contactInfo.title') }
              </h2>
              <div className="divider_container">
                <div className="divider"></div>
              </div>
              <div className="hk">
                <h3>
                  { t('contact.contactInfo.hk') }
                </h3>
                <p>
                  <FontAwesomeIcon icon={faPhone} flip="horizontal" size="xs"/>
                  (852) 3426 3794
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  info@cbesg.com
                </p>
              </div>
              <div className="divider_container">
                <div className="divider"></div>
              </div>
              <div className="tw">
                <h3>
                  { t('contact.contactInfo.tw') }
                </h3>
                <p>
                  <FontAwesomeIcon icon={faPhone} flip="horizontal" size="xs"/>
                  (886)2 7751 6005
                </p>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} />
                  info@cbesg.com
                </p>
              </div>
              
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default Contact;