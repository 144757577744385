import React from 'react';
import img1 from '../../img/Blog_img_6_1.jpg';
import img2 from '../../img/Blog_img_6_2.jpg';
import img3 from '../../img/Blog_img_6_3.jpg';
import img4 from '../../img/Blog_img_6_4.jpg';
import img5 from '../../img/Blog_img_6_5.png';
import img6 from '../../img/Blog_img_6_6.png';
import { useTranslation } from 'react-i18next';

const Blog6 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '台灣經濟部商業司為協助電子商務業者跨境發展，以提升交易額成長動能、厚植產業競爭力，依據「經濟部協助產業創新活動補助獎勵及輔導辦法」及「經濟部對民間 團體及個人補（捐）助預算執行管考作業注意事項」，訂定「108 年度跨境電商國際拓展推動計畫補助作業申請須知」， 補助申請昨（十四）日起開始受理，將至四月十五日止。',
    'text2': 
      '申請資格包含：',
    'text3': 
      '一、國內依公司法登記成立之公司，且公司負責人及經理人未具有「大陸地區人民來臺投資許可辦法」。',
    'text4': 
      '二、非屬銀行拒絕往來戶，且最近一年度公司淨值為正值。',
    'text5': 
      '計畫執行時間：',
    'text6': 
      '2019年 4 月 16 日起至 2020 年 2 月 15 日止。',
    'text7': 
      '補助目的係應用政策資源鼓勵電商業者投入市場應用創新， 帶領生態體系推動跨境商務發展，進而提升跨境交易額。補助範疇也包含「直接創造」跨境交易額或「間接增進」跨境交易額之電商業者 均可提出申請，包括：自有電商平臺、網站或專區直接創造跨境 交易額之業者，或是協助臺灣電商業者創造或提升跨境交易額之業。以及為配合政府政策強化臺灣與東南亞電商產業的連結，以及拓展東南亞市場更多合作機會，提案廠商能夠參與聯合網購節並作為計 畫目標之一，亦納入本計畫的補助範疇。',
    'text8': 
      '申請類別：',
    'text9': 
      '平台電商',
    'text10': 
      '本類業者在海外市場以自有電商平臺/專區/網站，銷售終端產品或 提供服務給消費者。提案重點應說明如何以科技為基礎創新商業模 式，提升平臺流量、擴增用戶、深化使用者體驗，並強調生態系的 垂直及水平整合發展。',
    'text11': 
      '代營運電商',
    'text12': 
      '本類業者為電商業者提供部分或全部電商營運、網路營銷等代營運 服務，以提升「臺灣電商」在海外市場的交易額。提案重點應說明 如何以科技為基礎創新商業模式，提升「臺灣電商」之經營效率及 跨境交易額，並擴充電商之生態系連結。',
    'text13': 
      '更多資訊：',
    'text14': 
      '108年跨境案申請須知(公告版)',
    'text15': 
      '唯高盛集團（CBES）作為全港首個一站式跨境電商服務的集團，正顛覆傳統產業，帶領客戶放眼全球，迎來前所未有機遇。集團旗下九家子公司，包括Avolution、Buyippee、Continental Global Service 、Ezomnia、IDS HK Company、Repairyi、Sellyippee 及 Transyippee，從物流、供應鏈、系統到人才，環環緊扣領造協同效應，為客戶提供至臻全面的電商服務。',
    'text16': 
      'A4lution 致力協助大中華區的製造商、分銷商、中小型企業、品牌提供一站式代營運服務。',
    'text17': 
      'Continental Global Service Ltd. 專為電商業界提供安全穩定的跨境物流一條龍服務。',
  };

  const text_cn = {
    'text1': 
      '台湾经济部商业司为协助电子商务业者跨境发展，以提升交易额成长动能、厚植产业竞争力，依据「经济部协助产业创新活动补助奖励及辅导办法」及「经济部对民间 团体及个人补（捐）助预算执行管考作业注意事项」，订定「108 年度跨境电商国际拓展推动计画补助作业申请须知」， 补助申请昨（十四）日起开始受理，将至四月十五日止。',
    'text2': 
      '申请资格包含：',
    'text3': 
      '一、国内依公司法登记成立之公司，且公司负责人及经理人未具有「大陆地区人民来台投资许可办法」。',
    'text4': 
      '二、非属银行拒绝往来户，且最近一年度公司净值为正值。',
    'text5': 
      '计画执行时间：',
    'text6': 
      '2019年 4 月 16 日起至 2020 年 2 月 15 日止。',
    'text7': 
      '补助目的系应用政策资源鼓励电商业者投入市场应用创新， 带领生态体系推动跨境商务发展，进而提升跨境交易额。补助范畴也包含「直接创造」跨境交易额或「间接增进」跨境交易额之电商业者 均可提出申请，包括：自有电商平台、网站或专区直接创造跨境 交易额之业者，或是协助台湾电商业者创造或提升跨境交易额之业。以及为配合政府政策强化台湾与东南亚电商产业的连结，以及拓展东南亚市场更多合作机会，提案厂商能够参与联合网购节并作为计 画目标之一，亦纳入本计画的补助范畴。',
    'text8': 
      '申请类别：',
    'text9': 
      '平台电商',
    'text10': 
      '本类业者在海外市场以自有电商平台/专区/网站，销售终端产品或 提供服务给消费者。提案重点应说明如何以科技为基础创新商业模 式，提升平台流量、扩增用户、深化使用者体验，并强调生态系的 垂直及水平整合发展。',
    'text11': 
      '代营运电商',
    'text12': 
      '本类业者为电商业者提供部分或全部电商营运、网路营销等代营运 服务，以提升「台湾电商」在海外市场的交易额。提案重点应说明 如何以科技为基础创新商业模式，提升「台湾电商」之经营效率及 跨境交易额，并扩充电商之生态系连结。',
    'text13': 
      '更多资讯：',
    'text14': 
      '108年跨境案申请须知(公告版)',
    'text15': 
      '唯高盛集团（CBES）作为全港首个一站式跨境电商服务的集团，正颠覆传统产业，带领客户放眼全球，迎来前所未有机遇。集团旗下九家子公司，包括Avolution、Buyippee、Continental Global Service 、Ezomnia、IDS HK Company、Repairyi、Sellyippee 及 Transyippee，从物流、供应链、系统到人才，环环紧扣领造协同效应，为客户提供至臻全面的电商服务。',
    'text16': 
      'A4lution 致力协助大中华区的制造商、分销商、中小型企业、品牌提供一站式代营运服务。',
    'text17': 
      'Continental Global Service Ltd. 专为电商业界提供安全稳定的跨境物流一条龙服务。',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
        <br />
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <p>
        { langTW && text_tw.text6 }
        { langCN && text_cn.text6 }
      </p>
      <p>
        { langTW && text_tw.text7 }
        { langCN && text_cn.text7 }
      </p>
      <p>
        { langTW && text_tw.text8 }
        { langCN && text_cn.text8 }
      </p>
      <ul>
        <li>
          { langTW && text_tw.text9 }
          { langCN && text_cn.text9 }
        </li>
      </ul>
      <p>
        { langTW && text_tw.text10 }
        { langCN && text_cn.text10 }
      </p>
      <ul>
        <li>
          { langTW && text_tw.text11 }
          { langCN && text_cn.text11 }
        </li>
      </ul>
      <p>
        { langTW && text_tw.text12 }
        { langCN && text_cn.text12 }
      </p>
      <img src={ img3 } alt="img3"/>
      <img src={ img4 } alt="img4"/>
      <p>
        { langTW && text_tw.text13 }
        { langCN && text_cn.text13 }
        <br />
        <a href="https://www.globalec.tw/" target="_blank" rel="noreferrer">https://www.globalec.tw/</a>
      </p>
      <p>
        <a href="https://gcis.nat.gov.tw/mainNew/publicContentAction.do?method=showFile&pkGcisPublicAttach=2026" target="_blank" rel="noreferrer">
          { langTW && text_tw.text14 }
          { langCN && text_cn.text14 }.pdf
        </a>
      </p>
      <p>
        { langTW && text_tw.text15 }
        { langCN && text_cn.text15 }
      </p>
      <img src={ img5 } alt="img5"/>
      <p>
        { langTW && text_tw.text16 }
        { langCN && text_cn.text16 }
      </p>
      <img src={ img6 } alt="img6"/>
      <p>
        { langTW && text_tw.text17 }
        { langCN && text_cn.text17 }
      </p>
    </>
  )
}

export default Blog6;