import React from 'react';
import img1 from '../../img/News_img_4_1.jpg';
import img2 from '../../img/News_img_4_2.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News4 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '在2019年2月公司即將展開新一章，唯高盛集團集結來自不同部門的菁英，致力於推出全港首間一站式跨境電商服務供應商，協助並拓展電子商務全球視野。',
    'text2':
      '我們已經備妥當迎接新的機遇！跟我們一起向前邁進！',
  };

  const text_cn = {
    'text1': 
      '在2019年2月公司即将展开新一章，唯高盛集团集结来自不同部门的菁英，致力于推出全港首间一站式跨境电商服务供应商，协助并拓展电子商务全球视野。',
    'text2':
      '我们已经备妥当迎接新的机遇！跟我们一起向前迈进！',
  };

  const text_en = {
    'text1': 
      'Our team of young and passionate talents are excited about the very big next step of CBES in Feb 2019! Leaders from different business units are coming together as one, with the vision of bringing the first ever one-stop solution to help the e-Commerce industry evolves and expands its global perspective.',
    'text2':
      'Let’s get going!',
  };

  return(
    <>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img1 } alt="img1"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img2 } alt="img2"/>
        </Col>
      </Row>
    </>
  )
}

export default News4;