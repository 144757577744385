import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from 'antd';
import '../styles/aboutUs.css';
import Banner from "../components/Banner";
import icon_1 from '../img/ICON01.png';
import icon_2 from '../img/ICON02.png';
import icon_3 from '../img/ICON03.png';
import quotationImg from '../img/Screenshot-2019-02-23-at-09.22.57.png';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function AboutUs(props) {
  const [ didViewCountUp, setDidViewCountUp ] = useState(false);

  const onVisibilityChange = isVisible => {
    if (isVisible) {
      setDidViewCountUp(true)
    }
  }

  const { t } = useTranslation();

  return(
    <>
      <MetaTags>
        <title>
          關於集團 | CBES 唯高盛集團
        </title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content className="aboutUs_content">
        <div className='wrapper-top' data-aos='fade-up'>
          <div className='wrapper-top-left'>
            <div className='icon-wrapper'>
              <img src={icon_1} alt='icon-1'/>
            </div>
            <div className='title-wrapper'>
              <h4 className='title'>
                { t('aboutUs.title.introduction') }
              </h4>
            </div>
            <div className='content-wrapper'>
              <p className='content'>
                { t('aboutUs.introduction.paragraph.1') }
              </p>
              <p className='content'>
                { t('aboutUs.introduction.paragraph.2') }
              </p>
              <p className='content'>
                { t('aboutUs.introduction.paragraph.3') }
              </p>
            </div>
          </div>
          <div className='wrapper-top-right'>
            <div className='icon-wrapper'>
              <img src={icon_2} alt='icon-2'/>
            </div>
            <div className='title-wrapper'>
              <h4 className='title'>
                { t('aboutUs.title.background') }
              </h4>
            </div>
            <div className='content-wrapper'>
              <p className='content'>
                { t('aboutUs.background.paragraph.1') }
              </p>
              <p className='content'>
                { t('aboutUs.background.paragraph.2') }
              </p>
              <p className='content'>
                { t('aboutUs.background.paragraph.3') }
              </p>
            </div>
          </div>
        </div>
        <div className='wrapper-middle' data-aos='fade-up'>
          <div className='icon-wrapper'>
            <img src={icon_3} alt='icon-3'/>
          </div>
          <div className='title-wrapper'>
            <h4 className='title'>
              { t('aboutUs.title.mission') }
            </h4>
          </div>
          <div className='content-wrapper'>
            <p className='content'>
              { t('aboutUs.mission.paragraph.1') }
            </p>
            <p className='content'>
              { t('aboutUs.mission.paragraph.2') }
            </p>
          </div>
          <div className='content-wrapper'>
            <h6>
              { t('aboutUs.mission.paragraph.3') }
            </h6>
            <p className='content'>
              { t('aboutUs.mission.paragraph.4') }
            </p>
            <p className='content'>
              { t('aboutUs.mission.paragraph.5') }
            </p>
          </div>
          <div className='wrapper-middle-left'>
            <div className='image-wrapper'>
              <img src={quotationImg} alt='quotation'/>
            </div>
          </div>
          <div className='wrapper-middle-right'>
            <p className='content'>
              <em>
                <strong>
                  { t('aboutUs.mission.quote.1') }
                </strong>
              </em>
            </p>
            <p className='content'>
              <em>
                <strong>
                  { t('aboutUs.mission.quote.2') }
                </strong>
              </em>
            </p>
          </div>
        </div>
        <div className='wrapper-bottom'>
          <div className='background-wrapper'>
            <div className='background'></div>
          </div>
          <div className='background-content-wrapper' data-aos='fade-up'>
            <div className='background-content-title'>
              <h3>
                { t('aboutUs.grade.title.1') }
              </h3>
              <span>
                { t('aboutUs.grade.title.2') }
              </span>
            </div>
            <div className='counter-wrapper' style={{clear: 'left'}}>
              <div className='counter'>
                <div className='counter-title'>
                  <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                    <CountUp start={53821} end={didViewCountUp ? 103821 : 0}/>
                  </VisibilitySensor>
                  <span>+</span>
                </div>
                <div className='divider'></div>
                <div className='counter-text'>
                  { t('aboutUs.counter.title.1') }
                </div>
              </div>
            </div>
            <div className='counter-wrapper'>
              <div className='counter'>
                <div className='counter-title'>
                  <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                    <CountUp start={0} end={didViewCountUp ? 30406 : 0}/>
                  </VisibilitySensor>
                  <span>+</span>
                </div>
                <div className='divider'></div>
                <div className='counter-text'>
                  { t('aboutUs.counter.title.2') }
                </div>
              </div>
            </div>
            <div className='counter-wrapper'>
              <div className='counter'>
                <div className='counter-title'>
                  <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                    <CountUp start={553735000} end={didViewCountUp ? 803735130 : 0}/>
                  </VisibilitySensor>
                  <span>+</span>
                </div>
                <div className='divider'></div>
                <div className='counter-text'>
                  { t('aboutUs.counter.title.3') }
                </div>
              </div>
            </div>
            <div className='counter-wrapper'>
              <div className='counter'>
                <div className='counter-title'>
                  <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                    <CountUp start={0} end={didViewCountUp ? 30 : 0}/>
                  </VisibilitySensor>
                  <span>%</span>
                </div>
                <div className='divider'></div>
                <div className='counter-text'>
                  { t('aboutUs.counter.title.4') }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default AboutUs;