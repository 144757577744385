import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Layout, Row, Col, Card, Button } from 'antd';
import '../styles/news.css';
import Banner from "../components/Banner";
import { useTranslation } from 'react-i18next';
import newsList from '../config/newsList';
import { FieldTimeOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Meta } = Card;

function News(props) {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  return(
    <>
      <MetaTags>
        <title>
          { langTW && '最新消息 | CBES 唯高盛集團' }
          { langCN && '最新消息 | CBES 唯高盛集團' }
          { langEN && 'NEWS | CBES 唯高盛集團' }
        </title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className="wrap">
          <div className="news_content">
            <Row gutter={24}>
              {
                [...newsList].reverse().map(item => (
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} key={ item.key }>
                    <Card
                      className="news_card"
                      cover={
                        <Link to={`/news/${item.key}`}>
                          <img
                            alt={ item.title_en }
                            src={ item.image.default }
                          />
                        </Link>
                      }
                    >
                      <div className="news_card_date">
                        <FieldTimeOutlined />
                        <span>{ item.time }</span>
                      </div>
                      <Meta
                        title={
                          <Link to={`/news/${item.key}`}>
                            <h2 className="news_card_title">
                              { langTW && item.title_tw }
                              { langCN && item.title_cn }
                              { langEN && item.title_en }
                            </h2>
                          </Link>
                        } 
                        description={  
                          <p className="news_card_description">
                            { langTW && item.description_tw }
                            { langCN && item.description_cn }
                            { langEN && item.description_en }
                          </p>
                        }
                      />
                      <Button className="news_card_btn">
                        <Link to={`/news/${item.key}`}>
                          { langTW && '繼續閱讀' }
                          { langCN && '继续阅读' }
                          { langEN && 'Read More' }
                        </Link>
                      </Button>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
      </Content>
    </>
  )
}

export default News;