import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../img/News_img_9_1.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News6 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '我們很榮幸地宣布，Continental和UPS已正式成為合作夥伴，為台灣中小型電商賣家提供遍及全球220個國家的UPS運輸服務。',
    'text2':
      '透過Continental和UPS的合作，您將在註冊後享有使用UPS運輸服務最高達86%OFF的優惠折扣，包括在系統內建立訂單、列印UPS標籤、追蹤包裹等多項服務。',
    'text3':
      '其中最大的優勢在於您無需再擔心繁雜的運輸合約和最低貨運量等限制，輕鬆使用UPS將您的貨物送達全世界。',
    'text4':
      'CBES集團總裁暨Continental負責人Stanley表示，疫情後全球的電商企業正蓄勢待發，而台灣賣家也懷抱著積極布局全球的心情。我們感到非常榮幸能為台灣市場提供全方位的UPS全球運輸服務，讓台灣賣家以最便捷的方式滿足業務上的物流需求。Continental將站在所有賣家的角度，致力於協助將貨物運送至全世界，期盼攜手共創疫情後的另一波事業高峰。',
    'text5':
      '如欲了解更多服務內容，歡迎來信至我們台灣團隊的信箱：twcs@contin-global.com。',
  };

  const text_cn = {
    'text1':
        '我们很荣幸地宣布，Continental和UPS已正式成为合作伙伴，为台湾中小型电商卖家提供遍及全球220个国家的UPS运输服务。',
    'text2':
        '透过Continental和UPS的合作，您将在注册后享有使用UPS运输服务最高达86%OFF的优惠折扣，包括在系统内建立订单、列印UPS标签、追踪包裹等多项服务。',
    'text3':
        '其中最大的优势在于您无需再担心繁杂的运输合约和最低货运量等限制，轻松使用UPS将您的货物送达全世界。',
    'text4':
        'CBES集团总裁暨Continental负责人Stanley表示，疫情后全球的电商企业正蓄势待发，而台湾卖家也怀抱着积极布局全球的心情。我们感到非常荣幸能为台湾市场提供全方位的UPS全球运输服务，让台湾卖家以最便捷的方式满足业务上的物流需求。 Continental将站在所有卖家的角度，致力于协助将货物运送至全世界，期盼携手共创疫情后的另一波事业高峰。',
    'text5':
        '如欲了解更多服务内容，欢迎来信至我们台湾团队的信箱：twcs@contin-global.com。',
  };

  const text_en = {
    'text1':
        'We are delighted to announce the collaboration between Continental and UPS, offering affordable UPS shipping solutions to Taiwanese e-commerce merchants, and enabling them to reach their customers in over 220 countries and territories worldwide.',
    'text2':
        'Save on the shipping services your business needs. Get discounts up to 86% off on UPS shipping and have access to convenient system features such as order creation, UPS label printing, and package tracking when you sign up.',
    'text3':
        'The greatest advantage lies in the elimination of complex shipping contracts and minimum shipping requirements, with UPS shipping on Continental, you can effortlessly deliver your products to customers worldwide.',
    'text4':
        'Stanley, President of CBES Group and Head of Continental, mentioned that post-COVID-19, global e-commerce businesses are gearing up, and Taiwanese sellers are actively expanding worldwide. We take immense pride in providing comprehensive UPS global shipping services to the Taiwanese market, enabling sellers to meet their logistics needs with utmost convenience. Continental is committed to assisting sellers in shipping goods worldwide and looks forward to jointly achieving new business heights after COVID-19.',
    'text5':
        'For more information about our services, feel free to contact our Taiwan team at twcs@contin-global.com.',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
        { langEN && text_en.text3 }
      </p>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
        { langEN && text_en.text4 }
      </p>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
        { langEN && text_en.text5 }
      </p>
    </>
  )
}

export default News6;