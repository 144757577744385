import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import '../styles/headerComp.css';
import logoImg from '../img/CBES_Logo.svg';
import { Link, useRouteMatch } from 'react-router-dom';
import { useLocation } from "react-router";
import NavbarMobileComp from '../components/NavbarMobileComp';
import { useTranslation } from 'react-i18next';

const { Header } = Layout;

const HeaderComp = ({ selectedKey, changeSelectedKey, isActive, handleToggle, visible, setVisible }) => {
  const location = useLocation();
  // console.log(location);
  const match = useRouteMatch("/blog/:blogId");
  const [ scale, setScale ] = useState(false);
  const handleScale = () => {
    let content = document.querySelector('.ant-layout-content');
    if(content.getBoundingClientRect().top <= 0){
      setScale(true);
    } else {
      setScale(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScale);

    return () => {
      window.removeEventListener('scroll', () => handleScale);
    }
  }, []);

  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };


  return(
    <>
      <Layout className={ scale ? "scale sticky" : null }>
        <Header className="headerComp"> 
          <div className="wrap">
            <div className="logo">
              <Link to="/">
                <img src={ logoImg } alt="cbes_logo"/>  
              </Link>
            </div>
            <NavbarMobileComp 
              selectedKey={ selectedKey }
              changeSelectedKey={ changeSelectedKey }
              handleToggle={ handleToggle }
              visible={ visible }
              setVisible={ setVisible }
            />
            <ul className="lang_control">
              <li>
                <button value="zh-TW" onClick={() => changeLanguage('zh-TW')} className={i18n.language === "zh-TW"? "active" : null}>繁中</button>
              </li>
              <li>
                <button value="zh-CN" onClick={() => changeLanguage('zh-CN')} className={i18n.language === "zh-CN"? "active" : null}>简中</button>
              </li>
              {
                location.pathname === "/blog" || ( match && match.path ) === '/blog/:blogId' ?
                null :
                <li>
                  <button value="en" onClick={() => changeLanguage('en')} className={i18n.language === "en"? "active" : null}>English</button>
                </li>
              }
            </ul>
          </div>
        </Header>
      </Layout>
    </>
  )
}

export default HeaderComp;