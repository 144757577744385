import React from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from 'antd';
import '../styles/ourCeo.css';
import Banner from '../components/Banner';
import avatarImg from '../img/Stanley_Lee.jpg';
import signImgTW from '../img/Stanley_Lee_sign_zhTW.jpg';
import signImgCN from '../img/Stanley_Lee_sign_zhCN.jpg';
import signImgEN from '../img/Stanley_Lee_sign_EN.jpg';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function OurCeo(props) {
  const { t, i18n } = useTranslation();

  let signImg;
  if(i18n.language === "zh-TW"){
    signImg = <img src={ signImgTW } alt="sign_img_zhTW"/>;
  } else if(i18n.language === "zh-CN"){  
    signImg = <img src={ signImgCN } alt="sign_img_zhCN"/>;
  } else if(i18n.language === "en"){  
    signImg = <img src={ signImgEN } alt="sign_img_en"/>;
  }

  return(
    <>
      <MetaTags>
        <title>關於總裁 | CBES 唯高盛集團</title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className="wrap">
          <div className="ourCeo_content">
            <div className="avatar">
              <img src={ avatarImg } alt="avatar" />
            </div>
            <h3>
              { t('ourCeo.title') }
            </h3>
            <div className="divider_container">
              <div className="divider"></div>
            </div>
            <div className="text">
              <p>
                { t('ourCeo.content.paragraph.1') }
              </p>
              <p>
                { t('ourCeo.content.paragraph.2') }
              </p>
              <p>
                { t('ourCeo.content.paragraph.3') }
              </p>
              <p>
                { t('ourCeo.content.paragraph.4') }
              </p>
              <p>
                { t('ourCeo.content.paragraph.5') }
              </p>
            </div>
            <div className="sign">{ signImg }</div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default OurCeo;
