import React from 'react';
import img1 from '../../img/Blog_img_10_1.jpg';
import img2 from '../../img/Blog_img_10_2.jpg';
import { useTranslation } from 'react-i18next';

const Blog10 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '根據報導，亞馬遜計劃在其倉庫引進新的自動包裝機，可以在美國境內減少1,300個工作崗位。亞馬遜還宣布推出一項新計劃，鼓勵現有的亞馬遜倉庫員工自己離職並轉往自己的亞馬遜商品相關業務。',
    'text2': 
      '這台名為Carton Wrap 1000的新機器由義大利CMC Machinery公司生產，機器根據測量訂購的貨物，折疊紙板，將箱子切割成尺寸，列印運輸標籤，並將每個包裹送去運送。新機器可以比人類快4到5倍。Carton Wrap機器預計將用於在美國各地的55個倉庫中，並可能取代每個工廠約24名員工。',
    'text3': 
      '“我們正在試用這項新技術，目標是提高安全性，加快交付時間，並提高整個網絡的效率，”該公司機器人部門的亞馬遜發言人通過電子郵件透露“我們預計，效率節省將重新投入到為客戶提供的全新新服務中，這將繼續創造新的就業機會。”',
    'text4': 
      '亞馬遜還宣布將推出一項新計劃，鼓勵現有倉庫員工退出並開辦自己的業務，為亞馬遜繼續服務。該倡議擴展了所謂的交付服務合作夥伴計劃，將為現有員工提供相當於三個月的總薪水和高達10,000美元的公司成本，鼓勵員工離職並轉往相關業務。',
    'text5': 
      '啟動資金中的10,000美元會去哪裡？想要創建自己的創業公司的離職員工可以租用亞馬遜品牌的麵包車和品牌制服。但離職員工將不再受亞馬遜新的15美元最低工資等保障，他們也沒有資格享受健康福利。',
    'text6': 
      '這項新的改革一定會造成不小的風波，但以電子商務的發展也會是一項新的改革。',
  };

  const text_cn = {
    'text1': 
      '根据报导，亚马逊计划在其仓库引进新的自动包装机，可以在美国境内减少1,300个工作岗位。亚马逊还宣布推出一项新计划，鼓励现有的亚马逊仓库员工自己离职并转往自己的亚马逊商品相关业务。',
    'text2': 
      '这台名为Carton Wrap 1000的新机器由义大利CMC Machinery公司生产，机器根据测量订购的货物，折叠纸板，将箱子切割成尺寸，列印运输标签，并将每个包裹送去运送。新机器可以比人类快4到5倍。 Carton Wrap机器预计将用于在美国各地的55个仓库中，并可能取代每个工厂约24名员工。',
    'text3': 
      '“我们正在试用这项新技术，目标是提高安全性，加快交付时间，并提高整个网络的效率，”该公司机器人部门的亚马逊发言人通过电子邮件透露“我们预计，效率节省将重新投入到为客户提供的全新新服务中，这将继续创造新的就业机会。”',
    'text4': 
      '亚马逊还宣布将推出一项新计划，鼓励现有仓库员工退出并开办自己的业务，为亚马逊继续服务。该倡议扩展了所谓的交付服务合作伙伴计划，将为现有员工提供相当于三个月的总薪水和高达10,000美元的公司成本，鼓励员工离职并转往相关业务。',
    'text5': 
      '启动资金中的10,000美元会去哪里？想要创建自己的创业公司的离职员工可以租用亚马逊品牌的面包车和品牌制服。但离职员工将不再受亚马逊新的15美元最低工资等保障，他们也没有资格享受健康福利。',
    'text6': 
      '这项新的改革一定会造成不小的风波，但以电子商务的发展也会是一项新的改革。',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <p>
        { langTW && text_tw.text6 }
        { langCN && text_cn.text6 }
      </p>
    </>
  )
}

export default Blog10;