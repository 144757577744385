import React from 'react';
import img1 from '../../img/Blog_img_11_1.jpg';
import img2 from '../../img/Blog_img_11_2.jpg';
import img3 from '../../img/Blog_img_11_3.jpg';
import { useTranslation } from 'react-i18next';

const Blog11 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '預計今年年底英國的B2C電子商務銷售額將超過2000億歐元。在2018年英國B2C電子商務銷售額約1750億歐元，這意味著今年與去年的情況相比成長了大約15個百分比!',
    'text2': 
      '根據Ecommerce Foundation 的報導，過去三年期間使用網路的人口佔全英國人口的95%，今年更上升一個百分點，到達96%，表示更增加了約66萬人具有使用網路的習慣。然而，同樣的資料顯示出這些數百萬人將在今年產生超過2000億歐元的電子商務銷售額，欲去年同期相比有著相當程度的成長。',
    'text3': 
      '談到英國的GDP(Gross Domestic Product)，目前電子商務銷售額佔GDP 7.94%，在今年可望突破8個百分比，也就是國內生產總值有超過8%都是由電子商務組成，相比2015年電子商務只佔GDP當中5.2%，上升了不少。Ecommerce Foundation也進一步指出，使用網路購物的人口並沒有大幅提升。在2017年使用網路購物人口佔總人口86%，今年也僅僅只有上升一個百分點達到87%。',
    'text4': 
      '即使消費的人口沒有大幅的提升，但每位網購的消費者卻消費大幅增加，2015年平均網購消費2525歐元，去年則為3254歐元，在今年專家更預估會達到了3620歐元。然而在一項調查中，超過40%的買家都表示Paypal是他們最喜歡的付款方式，其次才是信用卡佔23.7%，顯示出每個國家有著不同的消費習慣!',
    'text5': 
      '資料來源：',
  };

  const text_cn = {
    'text1': 
      '预计今年年底英国的B2C电子商务销售额将超过2000亿欧元。在2018年英国B2C电子商务销售额约1750亿欧元，这意味着今年与去年的情况相比成长了大约15个百分比!',
    'text2': 
      '根据Ecommerce Foundation 的报导，过去三年期间使用网路的人口占全英国人口的95%，今年更上升一个百分点，到达96%，表示更增加了约66万人具有使用网路的习惯。然而，同样的资料显示出这些数百万人将在今年产生超过2000亿欧元的电子商务销售额，欲去年同期相比有着相当程度的成长。',
    'text3': 
      '谈到英国的GDP(Gross Domestic Product)，目前电子商务销售额占GDP 7.94%，在今年可望突破8个百分比，也就是国内生产总值有超过8%都是由电子商务组成，相比2015年电子商务只占GDP当中5.2%，上升了不少。 Ecommerce Foundation也进一步指出，使用网路购物的人口并没有大幅提升。在2017年使用网路购物人口占总人口86%，今年也仅仅只有上升一个百分点达到87%。',
    'text4': 
      '即使消费的人口没有大幅的提升，但每位网购的消费者却消费大幅增加，2015年平均网购消费2525欧元，去年则为3254欧元，在今年专家更预估会达到了3620欧元。然而在一项调查中，超过40%的买家都表示Paypal是他们最喜欢的付款方式，其次才是信用卡占23.7%，显示出每个国家有着不同的消费习惯!',
    'text5': 
      '资料来源：',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
        <a href="https://www.ecommercefoundation.org/" target="_blank" rel="noreferrer">https://www.ecommercefoundation.org/</a>
      </p>
    </>
  )
}

export default Blog11;