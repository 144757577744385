import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../lang/en.json';
import cn from '../lang/zh-cn.json';
import tw from '../lang/zh-tw.json';

const resources = {
   'en': {
     translation: en,
   },
   'zh-CN': {
     translation: cn,
   },
   'zh-TW': {
     translation: tw,
   },
 };
 
 i18n.use(initReactI18next).init({
   resources,
   lng: 'zh-TW',             // default language
   fallbackLng: 'zh-TW',     // use this language if there is no corresponding value
   interpolation: {
     escapeValue: false,
   },
 });

export default i18n;