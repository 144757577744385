import React from 'react';
import img1 from '../../img/Blog_img_3_1.jpg';
import img2 from '../../img/Blog_img_3_2.jpg';
import img3 from '../../img/Blog_img_3_3.jpg';
import img4 from '../../img/Blog_img_3_4.jpg';
import img5 from '../../img/Blog_img_3_5.png';
import { useTranslation } from 'react-i18next';

const Blog3 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      'Continental 大陸集團雖在多數人的印象是輪胎品牌，不過他們在新一代無人車技術也相當積極投入，在今年 CES ，他們發表了一項一車兩用的無人車兼無人快遞的概念，透過把自駕無人車搭配機器狗，在無人車需求的離峰時間可作為快遞服務，提升無人化技術與無人車的附加價值。',
    'text2': 
      '根據Continental的調查顯示，貨運、快遞往住宅區的市場正逐年提高，由於送往住宅區的多半可能是急件，這也意味著運輸成本變得越來越重要，這也就是Continental要提出CUbE (Urban Mobility Experience )自動物流巴士的主因，首先人力成本甚至更大於運輸成本，二來也可以解決運輸業長期缺工的情形。',
    'text3': 
      '外型如接駁巴士的CUbE擁有高度的自動化駕駛為基礎，而且為了對應零排放的需求全面採用電氣化，當自動物流巴士按照客戶要求前往指定地點送件或收件，也由於他可以一天工作24小時的連續工作不間斷，不僅促使私家車的需求量大減，也能大大降低都市雍塞、廢氣排放、交通意外的情形發生。',
    'text4': 
      '今年此項計畫利用稱為 CUBE 的無人車作為全自動駕駛車輛，搭配 ANYmal 狗型四足機器人構成，此項計畫的出發點是考慮到無人車發展成熟後，人們會習慣透過無人車通勤在早晚的需求較高，而在上下班時間之外則是離峰時間、無人車可能就會閒置，故在搭配 ANYmal 狗型四足機器人後，能使無人車在這些離峰時間變成無人化的快遞運送之用。此時以往的物流士並不需要完全參與貨物的運送，而是可以交由內建的多個機器狗來擔任起搬運工的角色，省時省力，同時解決人力短缺的問題。',
    'text5': 
      '然而 ANYmal 的狗型機器人是大陸集團與蘇黎世技術大學共同開發，除了考慮對各種地形的適應性，狗型設計的背上也可作為成載貨物之用，搭配先進 AI 技術即可提供自動化的輸送服務，能夠拿取或是取箱子，最後也完成無人化物流的最後一哩路。',
  };

  const text_cn = {
    'text1': 
      'Continental 大陆集团虽在多数人的印象是轮胎品牌，不过他们在新一代无人车技术也相当积极投入，在今年CES ，他们发表了一项一车两用的无人车兼无人快递的概念，透过把自驾无人车搭配机器狗，在无人车需求的离峰时间可作为快递服务，提升无人化技术与无人车的附加价值。',
    'text2': 
      '根据Continental的调查显示，货运、快递往住宅区的市场正逐年提高，由于送往住宅区的多半可能是急件，这也意味着运输成本变得越来越重要，这也就是Continental要提出CUbE ( Urban Mobility Experience )自动物流巴士的主因，首先人力成本甚至更大于运输成本，二来也可以解决运输业长期缺工的情形。',
    'text3': 
      '外型如接驳巴士的CUbE拥有高度的自动化驾驶为基础，而且为了对应零排放的需求全面采用电气化，当自动物流巴士按照客户要求前往指定地点送件或收件，也由于他可以一天工作24小时的连续工作不间断，不仅促使私家车的需求量大减，也能大大降低都市雍塞、废气排放、交通意外的情形发生。',
    'text4': 
      '今年此项计画利用称为CUBE 的无人车作为全自动驾驶车辆，搭配ANYmal 狗型四足机器人构成，此项计画的出发点是考虑到无人车发展成熟后，人们会习惯透过无人车通勤在早晚的需求较高，而在上下班时间之外则是离峰时间、无人车可能就会闲置，故在搭配ANYmal 狗型四足机器人后，能使无人车在这些离峰时间变成无人化的快递运送之用。此时以往的物流士并不需要完全参与货物的运送，而是可以交由内建的多个机器狗来担任起搬运工的角色，省时省力，同时解决人力短缺的问题。',
    'text5': 
      '然而ANYmal 的狗型机器人是大陆集团与苏黎世技术大学共同开发，除了考虑对各种地形的适应性，狗型设计的背上也可作为成载货物之用，搭配先进AI 技术即可提供自动化的输送服务，能够拿取或是取箱子，最后也完成无人化物流的最后一哩路。',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <img src={ img5 } alt="img5"/>
    </>
  )
}

export default Blog3;