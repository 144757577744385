import React from 'react';
import MetaTags from 'react-meta-tags';
import { Layout, Row, Col, Divider, Button } from 'antd';
import '../styles/services.css';
import Banner from '../components/Banner';
import buyippeeImg from '../img/buyippee-1.png';
import continentalImg from '../img/Continental-1.png';
import idsHomeImg from '../img/ids_home_logo.png';
import icon_2 from '../img/icon-2.jpg';
import icon_3 from '../img/icon-3.jpg';
import icon_5 from '../img/icon-5.jpg';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function Services(props) {
  const contentStyle = {
    fontSize: '16px',
    textTransform: 'none',
    color: '#727272',
    lineHeight: 1.7,
    marginBottom: '20px',
  }

  const { t } = useTranslation();
  
  return(
    <>
      <MetaTags>
        <title>核心業務 | CBES 唯高盛集團</title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className='wrapper service_content'>
          <Row>
            <Col>
              <div className='card'>
                <div className='content-wrapper' data-aos='fade-left'>
                  <div className='image-wrapper'>
                    <a href='http://buyippee.com' target='_blank' rel='noreferrer'>
                      <img src={buyippeeImg} alt='buyippee'/>
                    </a>
                  </div>
                  <div className='title-wrapper'>
                    <h3>
                      <a href='http://buyippee.com' target='_blank' rel='noreferrer'>
                        { t('service.buyippee.title') }
                      </a>
                    </h3>
                  </div>
                  <div>
                    <p>
                      { t('service.buyippee.intro') }
                    </p>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.buyippee.feature.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.buyippee.feature.1') }
                      </li>
                      <li>
                        { t('service.buyippee.feature.2') }
                      </li>
                      <li>
                        { t('service.buyippee.feature.3') }
                      </li>
                      <li>
                        { t('service.buyippee.feature.4') }
                      </li>
                      <li>
                        { t('service.buyippee.feature.5') }
                      </li>
                    </ul>
                    <p>
                      { t('service.buyippee.conclusion') }
                    </p>
                  </div>
                  <div>
                    <img src={icon_2} alt='icon_2'/>
                  </div>
                  <Divider className='divider'/>
                  <div className='button-wrapper'>
                    <Button href='http://buyippee.com' size='large'>
                      { t('service.more.btn') }
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='card'>
                <div className='content-wrapper' data-aos='fade-right' data-aos-delay='200'>
                  <div className='image-wrapper'>
                    <a href='http://www.contin-global.com' target='_blank' rel='noreferrer'>
                      <img src={continentalImg} alt='continental'/>
                    </a>
                  </div>
                  <div className='title-wrapper'>
                    <h3>
                      <a href='http://www.contin-global.com' target='_blank' rel='noreferrer'>
                        { t('service.continental.title') }
                      </a>
                    </h3>
                  </div>
                  <div>
                    <p>
                      { t('service.continental.intro') }
                    </p>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.continental.feature.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.continental.feature.1') }
                      </li>
                      <li>
                        { t('service.continental.feature.2') }
                      </li>
                      <li>
                        { t('service.continental.feature.3') }
                      </li>
                    </ul>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.services.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.continental.services.1') }
                      </li>
                      <li>
                        { t('service.continental.services.2') }
                      </li>
                      <li>
                        { t('service.continental.services.3') }
                      </li>
                      <li>
                        { t('service.continental.services.4') }
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img src={icon_3} alt='icon_3'/>
                  </div>
                  <Divider className='divider'/>
                  <div className='button-wrapper'>
                    <Button href='http://www.contin-global.com' size='large'>
                      { t('service.more.btn') }
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='card'>
                <div className='content-wrapper' data-aos='fade-left'>
                  <div className='image-wrapper'>
                    <a href='http://www.ids.hk/' target='_blank' rel='noreferrer'>
                      <img src={idsHomeImg} alt='idsHome'/>
                    </a>
                  </div>
                  <div className='title-wrapper'>
                    <h3>
                      <a href='http://www.ids.hk/' target='_blank' rel='noreferrer'>
                        { t('service.idsHome.title') }
                      </a>
                    </h3>
                  </div>
                  <div>
                    <p>
                      { t('service.idsHome.intro') }
                    </p>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.idsHome.feature.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.idsHome.feature.1') }
                      </li>
                      <li>
                        { t('service.idsHome.feature.2') }
                      </li>
                      <li>
                        { t('service.idsHome.feature.3') }
                      </li>
                    </ul>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.services.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.idsHome.services.1') }
                      </li>
                      <li>
                        { t('service.idsHome.services.2') }
                      </li>
                      <li>
                        { t('service.idsHome.services.3') }
                      </li>
                      <li>
                        { t('service.idsHome.services.4') }
                      </li>
                      <li>
                        { t('service.idsHome.services.5') }
                      </li>
                      <li>
                        { t('service.idsHome.services.6') }
                      </li>
                    </ul>
                    <p>
                      <strong style={{textDecoration: 'underline', color: '#1ebef0'}}>
                        { t('service.onlineStoreLink.title') }
                      </strong>
                    </p>
                    <ul style={{...contentStyle, marginLeft: '35px', marginBottom: '20px'}}>
                      <li>
                        { t('service.idsHome.onlineShopLink.1') }
                      </li>
                      <li>
                        { t('service.idsHome.onlineShopLink.2') }
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img src={icon_5} alt='icon_5'/>
                  </div>
                  <Divider className='divider'/>
                  <div className='button-wrapper'>
                    <Button href='https://inodesignfurniture.com/' size='large'>
                      { t('service.more.btn') }
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </>
  )
}

export default Services;
