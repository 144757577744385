import React from 'react';
import img1 from '../../img/Blog_img_7_1.png';
import img2 from '../../img/Blog_img_7_2.png';
import img3 from '../../img/Blog_img_7_3.png';
import { useTranslation } from 'react-i18next';

const Blog7 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      'Instagram正開啟一個全新的收入來源，由於增加了付款相關的資訊，全球13億用戶只要點擊他們想要在購買的商品時，會出現一個新的“Checkout On Instagram”按鈕。點擊之後，消費者會在付款前選擇尺寸和顏色，然而，“Checkout on Instagram“ 這個功能今天起開始在美國啟用，包含Nike, Adidas, Dior, H&M, Michael Kors, Prada, Uniqlo, Warby Parker, Zara …等等多達20多個品牌過去不能直接在Instagram上購賣的產品現在都將有此項功能。消費者也能應用程式內聯絡賣家、追蹤或取消他們所購買的包裹；商家只會獲得完成訂單所需的詳細信息，包括聯繫信息和地址，但不包括您的實際付款信息。',
    'text2': 
      'Instagram的發言人也證實”Instagram將推出成交費好來維持平台的運作。但目前這樣成交費用並沒有一個具體的數字。這項成交費也還在測試的期間。但這項費用並不會改變消費者所購買物品的價格。發言人更指出這樣成交費是希望賣家付出成本換去轉換率，而不是用來強迫消費者支付通過Instagram應用程式購賣的”便利費用”。同時，Instagram增加電子商務服務，也能為廣告上的業務得到提升，Checkout 的功能也可以說服更多品牌對於社交網路產生更好的回饋。',
    'text3': 
      '然而，這不是Instagram第一次涉足電子商務領域。早在2016年Instagram 增設在貼文及限時動態中含有購物的功能。但是這些交易必須連結至Instagram以外的應用程式才能完成。除此之外，現今用戶能通過Visa, Mastercard, American Express, Discover和Paypal來購買他們所喜愛的商品。官方也保證之後將推出更多不一樣的功能來為電子商務發展出不同的篇章。',
  };

  const text_cn = {
    'text1': 
      '对于喜欢在美国亚马逊网站购物的朋友有福了!以往可能都需要请在美国的朋友帮你代购回香港和台湾，现在你到美国亚马逊将会变得更简单，因为现在终于支援中文介面了!',
    'text2': 
      '美国电商龙头亚马逊近期被发现开始支援繁体中文，其实原本美国亚马逊网站就支援了英文、西班牙文、德文、葡萄牙文和简体中文。对于香港和台湾的消费者来说，简体中文已经比西班牙文、德文等语言更容易阅读，但是繁体中文依旧比较亲切而且自然。现在，只要打开浏览器进入美国亚马逊，就可以看见首页上方横幅显示’’现在您可以使用繁体中文在Amazon上购物’’并且网站还显示目前网站上共有4500万个产品可以直送台湾和香港，对于喜爱网购和美国当地才特有产品来说，是个很不错的服务。',
    'text3': 
      '根据部分消费者的体验，美国亚马逊支援繁体中文的部分主要是将商品名称、选项、说明改成繁体中文。然而使用者的评论和主要商品的介绍并没有完全翻译成繁体中文。不过相较于英文、德文、西班牙文等等这类不同语系来说，繁体中文介面也亲民和自然不少。不过比较遗憾的部分，吸引不少香港和台湾消费者的日本亚马逊目前还没有支援繁体中文的部分，或许在往后的日子会是大家期盼的新服务!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <img src={ img2 } alt="img2"/>
      <br /><br />
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
    </>
  )
}

export default Blog7;