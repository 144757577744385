import React from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from 'antd';
import '../styles/ourTeam.css';
import Banner from '../components/Banner';
import kenImg from '../img/Ken_Wong.jpg';
import KarrieImg from '../img/Karrie_Yue.jpg';
import AllenImg from '../img/Allen_Kwong.jpg';
import JackyImg from '../img/Jacky_Lam.jpg';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function OurTeam(props) {
  const { t } = useTranslation();

  return(
    <>
      <MetaTags>
        <title>集團菁英 | CBES 唯高盛集團</title>
      </MetaTags>
      <Banner data={ props.data } />
      <Content>
        <div className="wrap">
          <div className="ourTeam_content">
            <div data-aos="fade-up" className="leaderList">
              <div className="item">
                <img src={ kenImg } alt="ken_img"/>
                <h3>
                  { t('ourTeam.title.ken') }
                </h3>
                <div className="divider_container">
                  <div className="divider"></div>
                </div>
                <p>
                  { t('ourTeam.motto.ken') }
                </p>
              </div>
              <div className="item">
                <img src={ KarrieImg } alt="karrie_img"/>
                <h3>
                  { t('ourTeam.title.karrie') }
                </h3>
                <div className="divider_container">
                  <div className="divider"></div>
                </div>
                <p>
                  { t('ourTeam.motto.karrie') }
                </p>
              </div>
              <div className="item">
                <img src={ AllenImg } alt="allen_img"/>
                <h3>
                  { t('ourTeam.title.allen') }
                </h3>
                <div className="divider_container">
                  <div className="divider"></div>
                </div>
                <p>
                  { t('ourTeam.motto.allen') }
                </p>
              </div>
              <div className="item">
                <img src={ JackyImg } alt="jacky_img"/>
                <h3>
                  { t('ourTeam.title.jacky') }
                </h3>
                <div className="divider_container">
                  <div className="divider"></div>
                </div>
                <p>
                  { t('ourTeam.motto.jacky') }
                </p>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </>
  )
}

export default OurTeam;
