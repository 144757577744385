import React from 'react';
import img1 from '../../img/Blog_img_4_1.png';
import img2 from '../../img/Blog_img_4_2.png';
import img3 from '../../img/Blog_img_4_3.png';
import img4 from '../../img/Blog_img_4_4.png';
import img5 from '../../img/Blog_img_4_5.png';
import { useTranslation } from 'react-i18next';

const Blog4 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '對於喜歡在美國亞馬遜網站購物的朋友有福了!以往可能都需要請在美國的朋友幫你代購回香港和台灣，現在你到美國亞馬遜將會變得更簡單，因為現在終於支援中文介面了!',
    'text2': 
      '美國電商龍頭亞馬遜近期被發現開始支援繁體中文，其實原本美國亞馬遜網站就支援了英文、西班牙文、德文、葡萄牙文和簡體中文。對於香港和台灣的消費者來說，簡體中文已經比西班牙文、德文等語言更容易閱讀，但是繁體中文依舊比較親切而且自然。現在，只要打開瀏覽器進入美國亞馬遜，就可以看見首頁上方橫幅顯示’’現在您可以使用繁體中文在Amazon上購物’’並且網站還顯示目前網站上共有4500萬個產品可以直送台灣和香港，對於喜愛網購和美國當地才特有產品來說，是個很不錯的服務。',
    'text3': 
      '根據部分消費者的體驗，美國亞馬遜支援繁體中文的部分主要是將商品名稱、選項、說明改成繁體中文。然而使用者的評論和主要商品的介紹並沒有完全翻譯成繁體中文。不過相較於英文、德文、西班牙文等等這類不同語系來說，繁體中文介面也親民和自然不少。不過比較遺憾的部分，吸引不少香港和台灣消費者的日本亞馬遜目前還沒有支援繁體中文的部分，或許在往後的日子會是大家期盼的新服務!',
    'text4': 
      '那到美國亞馬遜網站購物會有哪些好處呢?許多商品的售價換算成港幣或台幣再加上運費，可能不會比香港或台灣購買還便宜，但如果想買一些美國獨賣的東西，現在透過Amazon購物就很方便!雖然每樣商品的國際運費會因重量、體積等等有不同的價格，部分商品還有機會無法運送至香港和台灣。舉例來說，不少喜歡閱讀的消費者所關心電子書，目前亞馬遜美國網站雖然有支援繁體中文，但是中文電子書並未上架。至於官網目前顯示Kindle系列機種無法運送至香港台灣地區。這時候就可以運用唯高盛集團旗下Buyippee買+易的服務!',
    'text5': 
      'Buyippee 很了解許多商品只有在國外才買的到，又或者價格比國內優惠許多，但是我們更瞭解在國外網站購物時您可能會面臨的問題，諸如語言隔閡、付款方式、高昂的國際運費及不確定的運送時程等，為了解決這些難題，於是買+易成立了! 買+易提供是一站式的全球代購代運服務，您可以輕鬆地上網搜尋喜愛的商品，只需將您想訂購的商品連結貼到我們的訂單系統，並完成訂金支付，剩下的就放心交給我們處理囉!',
  };

  const text_cn = {
    'text1': 
      '对于喜欢在美国亚马逊网站购物的朋友有福了!以往可能都需要请在美国的朋友帮你代购回香港和台湾，现在你到美国亚马逊将会变得更简单，因为现在终于支援中文介面了!',
    'text2': 
      '美国电商龙头亚马逊近期被发现开始支援繁体中文，其实原本美国亚马逊网站就支援了英文、西班牙文、德文、葡萄牙文和简体中文。对于香港和台湾的消费者来说，简体中文已经比西班牙文、德文等语言更容易阅读，但是繁体中文依旧比较亲切而且自然。现在，只要打开浏览器进入美国亚马逊，就可以看见首页上方横幅显示’’现在您可以使用繁体中文在Amazon上购物’’并且网站还显示目前网站上共有4500万个产品可以直送台湾和香港，对于喜爱网购和美国当地才特有产品来说，是个很不错的服务。',
    'text3': 
      '根据部分消费者的体验，美国亚马逊支援繁体中文的部分主要是将商品名称、选项、说明改成繁体中文。然而使用者的评论和主要商品的介绍并没有完全翻译成繁体中文。不过相较于英文、德文、西班牙文等等这类不同语系来说，繁体中文介面也亲民和自然不少。不过比较遗憾的部分，吸引不少香港和台湾消费者的日本亚马逊目前还没有支援繁体中文的部分，或许在往后的日子会是大家期盼的新服务!',
    'text4': 
      '那到美国亚马逊网站购物会有哪些好处呢?许多商品的售价换算成港币或台币再加上运费，可能不会比香港或台湾购买还便宜，但如果想买一些美国独卖的东西，现在透过Amazon购物就很方便!虽然每样商品的国际运费会因重量、体积等等有不同的价格，部分商品还有机会无法运送至香港和台湾。举例来说，不少喜欢阅读的消费者所关心电子书，目前亚马逊美国网站虽然有支援繁体中文，但是中文电子书并未上架。至于官网目前显示Kindle系列机种无法运送至香港台湾地区。这时候就可以运用唯高盛集团旗下Buyippee买+易的服务!',
    'text5': 
      'Buyippee 很了解许多商品只有在国外才买的到，又或者价格比国内优惠许多，但是我们更了解在国外网站购物时您可能会面临的问题，诸如语言隔阂、付款方式、高昂的国际运费及不确定的运送时程等，为了解决这些难题，于是买+易成立了! 买+易提供是一站式的全球代购代运服务，您可以轻松地上网搜寻喜爱的商品，只需将您想订购的商品连结贴到我们的订单系统，并完成订金支付，剩下的就放心交给我们处理啰!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <img src={ img5 } alt="img5"/>
    </>
  )
}

export default Blog4;