import React from 'react';
import img1 from '../../img/News_img_6_1.jpg';
import img2 from '../../img/News_img_6_2.jpg';
import img3 from '../../img/News_img_6_3.jpg';
import img4 from '../../img/News_img_6_4.jpg';
import img5 from '../../img/News_img_6_5.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News6 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      'IDS集團不經不覺已成立二十週年，當晚香港員工、海外代表和合作機構聚首一堂，慶祝本集團二十年來的成果，並展望未來，更重要是感謝各嘉賓對本集團的支持。',
  };

  const text_cn = {
    'text1': 
      'IDS集团不经不觉已成立二十周年，当晚香港员工、海外代表和合作机构聚首一堂，庆祝本集团二十年来的成果，并展望未来，更重要是感谢各嘉宾对本集团的支持。',
  };

  const text_en = {
    'text1': 
      'IDS Hong Kong Company has reached the 20th Anniversary of establishment! Teams from Hong Kong, overseas and our strategic partners came together to celebrate the major achievements of the company and to prosper an even rosy future. We sincerely appreciate the support we have received along our journey and we aspire to thrive in all endeavor in the future!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <img src={ img2 } alt="img2"/>
      <br /><br />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <img src={ img3 } alt="img3"/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <img src={ img4 } alt="img4"/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <img src={ img5 } alt="img5"/>
        </Col>
      </Row>
    </>
  )
}

export default News6;