import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../img/News_img_7_1.jpg';
import img2 from '../../img/News_img_7_2.jpg';
import img3 from '../../img/News_img_7_3.png';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News6 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '非常感謝Capital Weekly的報導!',
    'text2': 
      '就像文章內說的，全球電商現今發展非常蓬勃，但香港廠家、品牌商、服務商及網商所面對的跨境電貿問題卻依然甚多。提供一站式跨境電商服務的我們看準商機，設立旗下九間子公司，集團總裁李基銓認為跨境電貿會遇到不少困難，尤其現在消費模式不斷改變，賣家、廠家及品牌往往因不熟悉電商業務，導致無從入手而卻步，但是只要選擇我們唯高盛集團的服務，一定可以解決以上疑難!',
    'text3': 
      '唯高盛集團核心業務',
  };

  const text_cn = {
    'text1': 
      '非常感谢Capital Weekly的报导!',
    'text2': 
      '就像文章内说的，全球电商现今发展非常蓬勃，但香港厂家、品牌商、服务商及网商所面对的跨境电贸问题却依然甚多。提供一站式跨境电商服务的我们看准商机，设立旗下九间子公司，集团总裁李基铨认为跨境电贸会遇到不少困难，尤其现在消费模式不断改变，卖家、厂家及品牌往往因不熟悉电商业务，导致无从入手而却步，但是只要选择我们唯高盛集团的服务，一定可以解决以上疑难!',
    'text3': 
      '唯高盛集团核心业务',
  };

  const text_en = {
    'text1': 
      'Much appreciated Capital Weekly’s report.',
    'text2': 
      'As the article said, the global E-Commerce is vigorously developing, but many manufacturers, brands, service providers, and online auctions in Hong Kong are still facing many problems of cross border E-commerce. CBES Group has provided one-stop E-commerce service, seized the business potential, and established nine entities so far. Stanley Lee, CBES Group CEO, thought that there are storms waiting for the cross border E-commerce, especially the consumption pattern is still changing constantly. Due to the unfamiliarity with the E-commerce business, most of the sellers, manufacturers and brands are not easy to start the E-commerce business. However, as long as you choose our services, you definitely can solve the problems above!',
    'text3': 
      'CBES Group services',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
        { langEN && text_en.text3 }
        &nbsp;&nbsp;
        <Link to='/service'>https://cbesg.com/service</Link>
      </p>
      <Row gutter={24}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img2 } alt="img2"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img3 } alt="img3"/>
        </Col>
      </Row>
    </>
  )
}

export default News6;