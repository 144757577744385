import React from 'react';
import { useLocation } from "react-router";
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import "../styles/menuComp.css";
import banner_about from '../img/banner_about.jpg';
import banner_news from '../img/banner_news.jpg';
import banner_service from '../img/banner_service.jpg';
import banner_blog from '../img/banner_blog.jpg';
import banner_partners from '../img/banner_partners.jpg';
import banner_contact from '../img/banner_contact.jpg';
import { useTranslation, Translation } from 'react-i18next';

const { SubMenu } = Menu;

const MenuComp = ({ selectedKey, changeSelectedKey }) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Menu mode="horizontal" selectedKeys={[ selectedKey ]} className="menuList">
        <Menu.Item key="home" onClick={ changeSelectedKey }>
          <Link 
            to={{ pathname: "/" }} 
            className={ location.pathname === "/"? "active" : null }
          >
            { t('page.title.home') }
          </Link>
        </Menu.Item>
        <SubMenu 
          key="SubMenu" 
          title={
            <Translation>{ t => <>{ t('page.title.aboutCbes') }</> }</Translation>
          }
          className={ location.pathname === "/aboutUs" || location.pathname === "/ourCeo" || location.pathname === "/ourTeam"? "active" : null }
        >
          <Menu.Item key="0" onClick={ changeSelectedKey }>
            <Link 
              to={{
                pathname: "/aboutUs",
                state:{ 
                  pageTitle: "關於集團", 
                  bannerImg: banner_about, 
                  bannerAlt: "banner-about-img"
                }
              }} 
              className={ location.pathname === "/aboutUs"? "active" : null }
            >
              { t('page.title.aboutUs') }
            </Link>
          </Menu.Item>
          <Menu.Item key="1" onClick={ changeSelectedKey }>
            <Link 
              to={{
                pathname: "/ourCeo",
                state:{ 
                  pageTitle: "關於總裁", 
                  bannerImg: banner_about, 
                  bannerAlt: "banner-about-img"
                }
              }} 
              className={ location.pathname === "/ourCeo"? "active" : null }
            >
              { t('page.title.ourCeo') }
            </Link>
          </Menu.Item>
          <Menu.Item key="2" onClick={ changeSelectedKey }>
            <Link 
              to={{
                pathname: "/ourTeam",
                state:{ 
                  pageTitle: "集團菁英", 
                  bannerImg: banner_about, 
                  bannerAlt: "banner-about-img"
                }
              }} 
              className={ location.pathname === "/ourTeam"? "active" : null }
            >
              { t('page.title.ourTeam') }
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="3" onClick={ changeSelectedKey }>
          <Link 
            to={{
              pathname: "/news",
              state:{ 
                pageTitle: "最新消息", 
                bannerImg: banner_news, 
                bannerAlt: "banner-news-img"
              }
            }} 
            className={ location.pathname === "/news"? "active" : null }
          >
            { t('page.title.news') }
          </Link>
        </Menu.Item>
        <Menu.Item key="4" onClick={ changeSelectedKey }>
          <Link 
            to={{
              pathname: "/service",
              state:{ 
                pageTitle: "核心業務", 
                bannerImg: banner_service, 
                bannerAlt: "banner-service-img"
              }
            }} 
            className={ location.pathname === "/service"? "active" : null }
          >
            { t('page.title.service') }
          </Link>
        </Menu.Item>
        {
          i18n.language === "en"?
          null :
          <Menu.Item key="5" onClick={ changeSelectedKey }>
            <Link 
              to={{
                pathname: "/blog",
                state:{ 
                  pageTitle: "電商日誌", 
                  bannerImg: banner_blog, 
                  bannerAlt: "banner-blog-img"
                }
              }} 
              className={ location.pathname === "/blog"? "active" : null }
            >
              { t('page.title.blog') }
            </Link>
          </Menu.Item>
        }
        <Menu.Item key="6" onClick={ changeSelectedKey }>
          <Link 
            to={{
              pathname: "/partners",
              state:{ 
                pageTitle: "合作夥伴", 
                bannerImg: banner_partners, 
                bannerAlt: "banner-partners-img"
              }
            }} 
            className={ location.pathname === "/partners"? "active" : null }
          >
            { t('page.title.partners') }
          </Link>
        </Menu.Item>
        <Menu.Item key="7" onClick={ changeSelectedKey } >
          <Link 
            to={{
              pathname: "/contact",
              state:{ 
                pageTitle: "聯絡我們", 
                bannerImg: banner_contact, 
                bannerAlt: "banner-contact-img"
              }
            }}
            className={ location.pathname === "/contact"? "active" : null }
          >
            { t('page.title.contact') }
          </Link>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default MenuComp;