import React from 'react';
import img1 from '../../img/News_img_1_1.jpg';
import img2 from '../../img/News_img_1_2.jpg';
import { useTranslation } from 'react-i18next';

const News1 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '2018唯高盛集團台灣分公司年終歲末尾牙圓滿結束，謝謝各位同仁共襄盛舉。尾牙於2019年1月24日(星期五) 在台北大直典華幸福機構與行，主題為五光十色。藉此機會感謝合作夥伴及同仁一年來所給予的支持、愛護和辛勞!',
  };

  const text_cn = {
    'text1': 
      '2018唯高盛集团台湾分公司年终岁末尾牙圆满结束，谢谢各位同仁共襄盛举。尾牙于2019年1月24日(星期五) 在台北大直典华幸福机构与行，主题为五光十色。借此机会感谢合作伙伴及同仁一年来所给予的支持、爱护和辛劳!',
  };

  const text_en = {
    'text1': 
      '2018 CBES Group Taiwan Annual Dinner was rounded out, thank you for supporting and participating in the event.Our Taiwan branch Annual Dinner was held on Friday, January 24, 2019 in Taipei Dazhi Denwell Hotel, under the theme “Brilliantly-Colored”. We would like to take this chance to thank you all our working partners in the past year, especially your support and care.',
  };

  return(
    <>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <img src={ img1 } alt="img1"/>
      <br /><br />
      <img src={ img2 } alt="img2"/>
    </>
  )
}

export default News1;