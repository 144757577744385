import Index from '../pages/Index';
import OurCeo from '../pages/OurCeo';
import OurTeam from '../pages/OurTeam';
import Partners from '../pages/Partners';
import Contact from '../pages/Contact';
import Services from '../pages/Services';
import AboutUs from '../pages/AboutUs';
import News from '../pages/News';
import NewsPost from '../pages/NewsPost';
import Blog from '../pages/Blog';
import BlogPost from '../pages/BlogPost';
import banner_about from '../img/banner_about.jpg';
import banner_news from '../img/banner_news.jpg';
import banner_service from '../img/banner_service.jpg';
import banner_blog from '../img/banner_blog.jpg';
import banner_partners from '../img/banner_partners.jpg';
import banner_contact from '../img/banner_contact.jpg';

export const RouteConfig = [
  { 
    path: "/",
    title: "首頁", 
    exact: true, 
    component: Index 
  },
  { 
    path: "/aboutUs",
    title: "關於集團", 
    exact: false, 
    component: () => 
    <AboutUs data={{
      state:{ 
        pageTitle: "關於集團", 
        bannerImg: banner_about, 
        bannerAlt: "banner-about-img"
      }}}
    /> 
  },
  { 
    path: "/ourCeo",
    title: "關於總裁", 
    exact: false, 
    component: () => 
    <OurCeo data={{
      state:{ 
        pageTitle: "關於總裁", 
        bannerImg: banner_about, 
        bannerAlt: "banner-about-img"
      }}}
    /> 
  },
  { 
    path: "/ourTeam", 
    title: "集團菁英", 
    exact: false, 
    component: () => 
    <OurTeam data={{
      state:{ 
        pageTitle: "集團菁英", 
        bannerImg: banner_about, 
        bannerAlt: "banner-about-img"
      }}}
    /> 
  },
  { 
    path: "/news", 
    title: "最新消息", 
    exact: true, 
    component: () => 
    <News data={{
      state:{ 
        pageTitle: "最新消息", 
        bannerImg: banner_news, 
        bannerAlt: "banner-news-img"
      }}}
    /> 
  },
  { 
    path: "/news/:newsId",
    title: "最新消息內頁", 
    exact: true, 
    component: NewsPost
  },
  { 
    path: "/service", 
    title: "核心業務", 
    exact: false, 
    component: () => 
    <Services data={{
      state:{ 
        pageTitle: "核心業務", 
        bannerImg: banner_service, 
        bannerAlt: "banner-service-img"
      }}}
    />
  },
  { 
    path: "/blog", 
    title: "電商日誌", 
    exact: true, 
    component: () => 
    <Blog data={{
      state:{ 
        pageTitle: "電商日誌", 
        bannerImg: banner_blog, 
        bannerAlt: "banner-blog-img"
      }}}
    /> 
  },
  { 
    path: "/blog/:blogId",
    title: "電商日誌內頁", 
    exact: true, 
    component: BlogPost
  },
  { 
    path: "/partners", 
    title: "合作夥伴", 
    exact: false, 
    component: () => 
    <Partners data={{
      state:{ 
        pageTitle: "合作夥伴", 
        bannerImg: banner_partners, 
        bannerAlt: "banner-partners-img"
      }}}
    />
  },
  { 
    path: "/contact", 
    title: "聯絡我們", 
    exact: false, 
    component: () => 
    <Contact data={{
      state:{ 
        pageTitle: "聯絡我們", 
        bannerImg: banner_contact, 
        bannerAlt: "banner-contact-img"
      }}}
    />
  }
]