import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout, Row, Col, Card } from 'antd';
import '../styles/newsPost.css';
import newsList from '../config/newsList';
import MetaTags from 'react-meta-tags';
import { FieldTimeOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import News1 from './news/1';
import News2 from './news/2';
import News3 from './news/3';
import News4 from './news/4';
import News5 from './news/5';
import News6 from './news/6';
import News7 from './news/7';
import News8 from './news/8';
import News9 from './news/9';

const { Content } = Layout;
const { Meta } = Card;

const newsData = [
  <News1 />,
  <News2 />,
  <News3 />,
  <News4 />,
  <News5 />,
  <News6 />,
  <News7 />,
  <News8 />,
  <News9 />,
];

const NewsPost = () => {
  let { newsId } = useParams();
  const { i18n } = useTranslation();
  console.log(newsId);

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  return(
    <>
      <MetaTags>
        <title>
          { langTW && newsList[newsId - 1].title_tw }
          { langCN && newsList[newsId - 1].title_cn }
          { langEN && newsList[newsId - 1].title_en }
        </title>
      </MetaTags>
      <Content>
        <div className="wrap">
          <div className="newsPost_content">
            <Row>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <div className="news_left">
                  <div className="news_title">
                    <Row>
                      <Col xs={0} sm={0} md={0} lg={2} xl={2} className="news_title_time">
                        <Row className="news_title_date">
                          { newsList[newsId - 1].date }
                        </Row>
                        <Row className="news_title_month">
                          { langTW && newsList[newsId - 1].month }
                          { langCN && newsList[newsId - 1].month }
                          { langEN && newsList[newsId - 1].month_en }
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={22} xl={22} className="news_title_text">
                        { langTW && newsList[newsId - 1].title_tw }
                        { langCN && newsList[newsId - 1].title_cn }
                        { langEN && newsList[newsId - 1].title_en }
                      </Col>
                    </Row>
                  </div>
                  <div className="news_date">
                    <Row>
                      <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                      <Col xs={24} sm={24} md={24} lg={22} xl={22}>
                        <FieldTimeOutlined />
                        <span>{ newsList[newsId - 1].time }</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="news_main">
                    { newsData[newsId - 1] }
                  </div>
                  <div className="news_page_control">
                    <Row>
                      <Col span={12}>
                        {
                          newsId === '1' ? 
                          <Link to={`/news/9`}>
                            <ArrowLeftOutlined />&nbsp;&nbsp;
                            { langTW && '上一則' }
                            { langCN && '上一则' }
                            { langEN && 'PREV' }
                          </Link>
                          :
                          <Link to={`/news/${parseInt(newsId) - 1}`}>
                            <ArrowLeftOutlined />&nbsp;&nbsp;
                            { langTW && '上一則' }
                            { langCN && '上一则' }
                            { langEN && 'PREV' }
                          </Link>
                        }
                      </Col>
                      <Col span={12} align="right">
                        {
                          newsId === '9' ?
                          <Link to={`/news/1`}>
                            { langTW && '下一則' }
                            { langCN && '下一则' }
                            { langEN && 'NEXT' }
                            &nbsp;&nbsp;<ArrowRightOutlined />
                          </Link>
                          :
                          <Link to={`/news/${parseInt(newsId) + 1}`}>
                            { langTW && '下一則' }
                            { langCN && '下一则' }
                            { langEN && 'NEXT' }
                            &nbsp;&nbsp;<ArrowRightOutlined />
                          </Link>
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>  
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="news_right">
                  <h3>近期消息</h3>
                  {
                    newsId === '9' ?
                        null :
                        <Row className="latest_news_item" gutter={24}>
                          <Col>
                            <div className="imgWrap">
                              <img
                                  alt={ newsList[8].title_en }
                                  src={ newsList[8].image.default }
                              />
                            </div>
                          </Col>
                          <Col span={16}>
                            <Link to='/news/9'>
                              <h4>
                                { langTW && newsList[8].title_tw }
                                { langCN && newsList[8].title_cn }
                                { langEN && newsList[8].title_en }
                              </h4>
                            </Link>
                            <FieldTimeOutlined />
                            <span>{ newsList[8].time }</span>
                          </Col>
                        </Row>
                  }
                  {
                    newsId === '8' ? 
                    null :
                    <Row className="latest_news_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ newsList[7].title_en }
                            src={ newsList[7].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/news/8'>
                          <h4>
                            { langTW && newsList[7].title_tw }
                            { langCN && newsList[7].title_cn }
                            { langEN && newsList[7].title_en }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ newsList[7].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    newsId === '7' ?
                    null : 
                    <Row className="latest_news_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ newsList[6].title_en }
                            src={ newsList[6].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/news/7'>
                          <h4>
                            { langTW && newsList[6].title_tw }
                            { langCN && newsList[6].title_cn }
                            { langEN && newsList[6].title_en }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ newsList[6].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    newsId === '9' || newsId === '8' || newsId === '7' ?
                    <Row className="latest_news_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ newsList[5].title_en }
                            src={ newsList[5].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/news/6'>
                          <h4>
                            { langTW && newsList[5].title_tw }
                            { langCN && newsList[5].title_cn }
                            { langEN && newsList[5].title_en }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ newsList[5].time }</span>
                      </Col>
                    </Row> :
                    null
                  }
                </div>
              </Col>  
            </Row>
          </div>
        </div>
      </Content>
    </>
  )
}

export default NewsPost