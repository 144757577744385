import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Layout, Row, Col, Card } from 'antd';
import '../styles/blogPost.css';
import blogList from '../config/blogList';
import MetaTags from 'react-meta-tags';
import { FieldTimeOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Blog1 from './blog/1';
import Blog2 from './blog/2';
import Blog3 from './blog/3';
import Blog4 from './blog/4';
import Blog5 from './blog/5';
import Blog6 from './blog/6';
import Blog7 from './blog/7';
import Blog8 from './blog/8';
import Blog9 from './blog/9';
import Blog10 from './blog/10';
import Blog11 from './blog/11';

const { Content } = Layout;
// const { Meta } = Card;

const blogData = [
  <Blog1 />,
  <Blog2 />,
  <Blog3 />,
  <Blog4 />,
  <Blog5 />,
  <Blog6 />,
  <Blog7 />,
  <Blog8 />,
  <Blog9 />,
  <Blog10 />,
  <Blog11 />,
];

const BlogPost = () => {
  let { blogId } = useParams();
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  return(
    <>
      <MetaTags>
        <title>
          { langTW && blogList[blogId - 1].title_tw }
          { langCN && blogList[blogId - 1].title_cn }
        </title>
      </MetaTags>
      <Content>
        <div className="wrap">
          <div className="blogPost_content">
            <Row>
              <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                <div className="blog_left">
                  <div className="blog_title">
                    <Row>
                      <Col xs={0} sm={0} md={0} lg={2} xl={2} className="blog_title_time">
                        <Row className="blog_title_date">
                          { blogList[blogId - 1].date }
                        </Row>
                        <Row className="blog_title_month">
                          { langTW && blogList[blogId - 1].month }
                          { langCN && blogList[blogId - 1].month }
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={22} xl={22} className="blog_title_text">
                        { langTW && blogList[blogId - 1].title_tw }
                        { langCN && blogList[blogId - 1].title_cn }
                      </Col>
                    </Row>
                  </div>
                  <div className="blog_date">
                    <Row>
                      <Col xs={0} sm={0} md={0} lg={2} xl={2}></Col>
                      <Col xs={24} sm={24} md={24} lg={22} xl={22}>
                        <FieldTimeOutlined />
                        <span>{ blogList[blogId - 1].time }</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="blog_main">
                    { blogData[blogId - 1] }
                  </div>
                  <div className="blog_page_control">
                    <Row>
                      <Col span={12}>
                        {
                          blogId === '1' ? 
                          <Link to={`/blog/11`}>
                            <ArrowLeftOutlined />&nbsp;&nbsp;
                            { langTW && '上一則' }
                            { langCN && '上一则' }
                          </Link>
                          :
                          <Link to={`/blog/${parseInt(blogId) - 1}`}>
                            <ArrowLeftOutlined />&nbsp;&nbsp;
                            { langTW && '上一則' }
                            { langCN && '上一则' }
                          </Link>
                        }
                      </Col>
                      <Col span={12} align="right">
                        {
                          blogId === '11' ? 
                          <Link to={`/blog/1`}>
                            { langTW && '下一則' }
                            { langCN && '下一则' }
                            &nbsp;&nbsp;<ArrowRightOutlined />
                          </Link>
                          :
                          <Link to={`/blog/${parseInt(blogId) + 1}`}>
                            { langTW && '下一則' }
                            { langCN && '下一则' }
                            &nbsp;&nbsp;<ArrowRightOutlined />
                          </Link>
                        }
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>  
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div className="blog_right">
                  <h3>近期文章</h3>
                  {
                    blogId === '11' ? 
                    null :
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[10].image_alt }
                            src={ blogList[10].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/11'>
                          <h4>
                            { langTW && blogList[10].title_tw }
                            { langCN && blogList[10].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[10].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '10' ?
                    null : 
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[9].image_alt }
                            src={ blogList[9].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/10'>
                          <h4>
                            { langTW && blogList[9].title_tw }
                            { langCN && blogList[9].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[9].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '9' ?
                    null :
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[8].image_alt }
                            src={ blogList[8].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/9'>
                          <h4>
                            { langTW && blogList[8].title_tw }
                            { langCN && blogList[8].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[8].time }</span>
                      </Col>
                    </Row>
                  }
                  {
                    blogId === '11' || blogId === '10' || blogId === '9' ?
                    <Row className="latest_blog_item" gutter={24}>
                      <Col>
                        <div className="imgWrap">
                          <img
                            alt={ blogList[7].image_alt }
                            src={ blogList[7].image.default }
                          />
                        </div>
                      </Col>
                      <Col span={16}>
                        <Link to='/blog/8'>
                          <h4>
                            { langTW && blogList[7].title_tw }
                            { langCN && blogList[7].title_cn }
                          </h4>
                        </Link>
                        <FieldTimeOutlined />
                        <span>{ blogList[7].time }</span>
                      </Col>
                    </Row> :
                    null
                  }
                </div>
              </Col>  
            </Row>
          </div>
        </div>
      </Content>
    </>
  )
}

export default BlogPost