import React from 'react';
import { useLocation } from "react-router";
import '../styles/banner.css';
import { Translation } from 'react-i18next';

const Banner = (props) => {
  const location = useLocation();
  // console.log(location.state);
  // console.log(props.data);

  let pageTitle;
  if(location.pathname === "/aboutUs"){
    pageTitle = <Translation>{ t => <>{ t('page.title.aboutUs') }</> }</Translation>
  } else if(location.pathname === "/ourCeo"){
    pageTitle = <Translation>{ t => <>{ t('page.title.ourCeo') }</> }</Translation>
  } else if(location.pathname === "/ourTeam"){
    pageTitle = <Translation>{ t => <>{ t('page.title.ourTeam') }</> }</Translation>
  } else if(location.pathname === "/news"){
    pageTitle = <Translation>{ t => <>{ t('page.title.news') }</> }</Translation>
  } else if(location.pathname === "/service"){
    pageTitle = <Translation>{ t => <>{ t('page.title.service') }</> }</Translation>
  } else if(location.pathname === "/blog"){
    pageTitle = <Translation>{ t => <>{ t('page.title.blog') }</> }</Translation>
  } else if(location.pathname === "/partners"){
    pageTitle = <Translation>{ t => <>{ t('page.title.partners') }</> }</Translation>
  } else if(location.pathname === "/contact"){
    pageTitle = <Translation>{ t => <>{ t('page.title.contact') }</> }</Translation>
  }

  return(
    <>
      <div className="bannerImg">
        <div className="wrap">
          <h2 className="title">{ pageTitle }</h2>
        </div>
        <img src={ props.data.state.bannerImg } alt={ props.data.state.bannerAlt } />
      </div>
    </>
  )
}
 
export default Banner;
