import React from 'react';
import img1_tw from '../../img/News_img_3_1_tw.png';
import img1_cn from '../../img/News_img_3_1_cn.png';
import img1_en from '../../img/News_img_3_1_en.png';
import { useTranslation } from 'react-i18next';

const News3 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '集團創辦人暨行政總裁李基銓先生 (Mr. Stanley Lee) 將分享如何利用嶄新的營運模式，協助企業在全球各地的電商市場上拓展業務。多名來自內地、香港及台灣的電商業界領袖，以及與電商業界相關的物流、金流、資訊系統等服務供應商代表等屆時亦將應邀參與。',
    'text2':
      '我們誠邀你撥冗光臨，一起見證此重要時刻！',
  };

  const text_cn = {
    'text1': 
      '集团创办人暨行政总裁李基铨先生 (Mr. Stanley Lee) 将分享如何利用崭新的营运模式，协助企业在全球各地的电商市场上拓展业务。多名来自内地、香港及台湾的电商业界领袖，以及与电商业界相关的物流、金流、资讯系统等服务供应商代表等届时亦将应邀参与。',
    'text2':
      '我们诚邀你拨冗光临，一起见证此重要时刻！',
  };

  const text_en = {
    'text1': 
      'Our founder and CEO Mr. Stanley Lee will illustrate how our one and only multidimensional game changing solution would be helping the industry to explore the global perspective of e-commerce market. Industry leaders in e-Commerce, logistics, payment gateway and software system from China, Hong Kong and Taiwan will be brought together to witness this significant moment.',
    'text2':
      'Please come and join us!',
  };

  return(
    <>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
        { langEN && text_en.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
        { langEN && text_en.text3 }
      </p>
      { langTW && <img src={ img1_tw } alt="img1_tw"/> }
      { langCN && <img src={ img1_cn } alt="img1_cn"/> }
      { langEN && <img src={ img1_en } alt="img1_en"/> }
    </>
  )
}

export default News3;