import React from 'react';
import img1 from '../../img/Blog_img_2_1.jpg';
import img2 from '../../img/Blog_img_2_2.jpg';
import img3 from '../../img/Blog_img_2_3.jpg';
import img4 from '../../img/Blog_img_2_4.jpg';
import { useTranslation } from 'react-i18next';

const Blog2 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '受到中美貿易的影響，美股持續震盪，市值排名不斷洗牌，2018年底時，微軟取代蘋果登上美股市值第一的寶座，美國時間2019年1月7日美國股市延續漲勢，道瓊工業指數收長近百點，而電商龍頭亞馬遜漲幅3%市值約7990億美元，超越微軟(MSFT – US)約7910億美元成為全球市值第一。然而，曾經市值破兆的蘋果(AAPL – US)在經歷下修財測大跌後，如今已降至約6990億美元，就連Google母公司Alphabet(GOOGL – US)都以市值約7470億美元贏過蘋果。',
    'text2': 
      '根據金融數據公司FactSet先前做的調查，分析師預估蘋果本季營收應能達到913美元。但蘋果營收卻低於預期9%，實非投資人樂見。庫克認為，中國市場是一大因素。中國近年經濟成長趨緩，中美貿易衝突，加上華為和小米，除了大中華地區外，其他地區對於IPhone新手機的渴求程度也逐漸降低。一部分的原因也是因為蘋果高端手機要價1000美元起跳，消費者較難接受。',
    'text3': 
      '從去年10月初高點至今，蘋果股價已跌將近3成。這樣的情勢可能連帶影響概股表現。韋德布希證券公司(Wedbush Securities)分析師Daniel Ives表示，「這是庫克時代蘋果最黑暗的日子。」她認為蘋果錯估貿易暫形勢，不斷在大中華地區推出高端手機，讓其他競爭對手有機會搶佔中低階手機市場。現在必須看蘋果如何改變銷售策略，否則很難重現先前光彩。',
    'text4': 
      '反觀亞馬遜，根據CNBC分析，亞馬遜有5項優勢促成其為目前市值第一。第一、雲端運算服務(Amazon Web Service)營收破230億美元，站雲端市場四成。AWS提共約140項服務，目前也有數百萬客戶。第二、亞馬遜去年電商銷量美國市占率將近50%，現今也積極拓展印度等市區市場。第三、亞馬遜旗下事業，比如智慧語音助理Alexa及健康、廣告事業皆表現亮眼。第四、不同其他科技業，亞馬遜領導階層多為較有經驗的管理者，向心力十足。執行長Jeff Bezos也曾強調，亞馬遜不會讓核心領導團隊S-Team出現過多的異動。最後第五、亞馬遜較少有大規模的發展。亞馬遜近來雖然也有不少爭議，包含來自美國總統川普的攻擊，尋覓第二總部時引發的傳言，但是這些都並未對其造成立即的危機。相較於蘋果與中國的糾葛、臉書Facebook的隱私權問題，亞馬遜的投資風險相對較低。',
  };

  const text_cn = {
    'text1': 
      '受到中美贸易的影响，美股持续震荡，市值排名不断洗牌，2018年底时，微软取代苹果登上美股市值第一的宝座，美国时间2019年1月7日美国股市延续涨势，道琼工业指数收长近百点，而电商龙头亚马逊涨幅3%市值约7990亿美元，超越微软(MSFT – US)约7910亿美元成为全球市值第一。然而，曾经市值破兆的苹果(AAPL – US)在经历下修财测大跌后，如今已降至约6990亿美元，就连Google母公司Alphabet(GOOGL – US)都以市值约7470亿美元赢过苹果。',
    'text2': 
      '根据金融数据公司FactSet先前做的调查，分析师预估苹果本季营收应能达到913美元。但苹果营收却低于预期9%，实非投资人乐见。库克认为，中国市场是一大因素。中国近年经济成长趋缓，中美贸易冲突，加上华为和小米，除了大中华地区外，其他地区对于IPhone新手机的渴求程度也逐渐降低。一部分的原因也是因为苹果高端手机要价1000美元起跳，消费者较难接受。',
    'text3': 
      '从去年10月初高点至今，苹果股价已跌将近3成。这样的情势可能连带影响概股表现。韦德布希证券公司(Wedbush Securities)分析师Daniel Ives表示，「这是库克时代苹果最黑暗的日子。」她认为苹果错估贸易暂形势，不断在大中华地区推出高端手机，让其他竞争对手有机会抢占中低阶手机市场。现在必须看苹果如何改变销售策略，否则很难重现先前光彩。',
    'text4': 
      '反观亚马逊，根据CNBC分析，亚马逊有5项优势促成其为目前市值第一。第一、云端运算服务(Amazon Web Service)营收破230亿美元，站云端市场四成。 AWS提共约140项服务，目前也有数百万客户。第二、亚马逊去年电商销量美国市占率将近50%，现今也积极拓展印度等市区市场。第三、亚马逊旗下事业，比如智慧语音助理Alexa及健康、广告事业皆表现亮眼。第四、不同其他科技业，亚马逊领导阶层多为较有经验的管理者，向心力十足。执行长Jeff Bezos也曾强调，亚马逊不会让核心领导团队S-Team出现过多的异动。最后第五、亚马逊较少有大规模的发展。亚马逊近来虽然也有不少争议，包含来自美国总统川普的攻击，寻觅第二总部时引发的传言，但是这些都并未对其造成立即的危机。相较于苹果与中国的纠葛、脸书Facebook的隐私权问题，亚马逊的投资风险相对较低。',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
    </>
  )
}

export default Blog2;