import React from 'react';
import img1 from '../../img/Blog_img_1_1.jpg';
import img2 from '../../img/Blog_img_1_2.jpg';
import img3 from '../../img/Blog_img_1_3.jpg';
import img4 from '../../img/Blog_img_1_4.jpg';
import img5 from '../../img/Blog_img_1_5.jpg';
import { useTranslation } from 'react-i18next';

const Blog1 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '馬雲曾說過：「不做電子商務，五年後你會後悔。十年後，你要是在不做電子商務的話，那麼你將無商可務。」根據eMarketer調查網站指出預估2020年全球將有21.14億人口使用網路購物，然而將有超過9億的人口使用跨境購物，幾乎是總網購人口的一半。',
    'text2': 
      '2018唯高盛集團台灣分公司年終歲末尾牙圓滿結束，謝謝各位同仁共襄盛舉。尾牙於2019年1月24日(星期五) 在台北大直典華幸福機構與行，主題為五光十色。藉此機會感謝合作夥伴及同仁一年來所給予的支持、愛護和辛勞!',
    'text3': 
      '這次講座很榮幸和台灣樂天市場首次合作，其中我們邀請了約80位賣家來參與，在講座的前半部主要分享除了工作之餘如何幫自己加薪以及代購賣家養成計畫，更重要的是Buyippee更推出出席講座便享有5公斤跨國運費免費試用體驗!',
    'text4': 
      '講座上半場的內容主要分成四個部分分別為全球跨境電商趨勢分析、分享代購賣家經營手法、傳授海外商品選務秘笈以及輕鬆了解進口關稅運費；下半場的內容則著重於樂天全球及在地化經營策略、全球四大電商平台模式解析以及百萬店鋪必備三大電商規劃。由於此次為台灣Buyippee首次講座，在會後，有許多感興趣的賣家和從來沒有使用過代運代購服務的朋友對於不同地區的服務費、運費、關稅….等等都想有更進一步的了解和諮詢，Buyippee團隊也一一為回答所有問題。藉由這次的講座也讓更多人有機會接觸Buyippee的服務，也是台灣團隊跨出的一大步。',
    'text5': 
      'Buyippee團隊是你們最專業的顧問，我們提供代購代運雙服務，在業界擁有最多海外地址，並且港台團隊同步經營，不必煩惱進口清關手續!',
  };

  const text_cn = {
    'text1': 
      '马云曾说过：「不做电子商务，五年后你会后悔。十年后，你要是在不做电子商务的话，那么你将无商可务。」根据eMarketer调查网站指出预估2020年全球将有21.14亿人口使用网路购物，然而将有超过9亿的人口使用跨境购物，几乎是总网购人口的一半。',
    'text2': 
      '根据2017年资策会产业情报研究所(MIC)指出台湾跨境网购主要消费族群落在26 ~ 35岁，且每年平均消费金额为16,378元。那至于海外网站购物三大诱因分别为价格实惠(69%)、种类多元(42.2)、折扣多或促销频繁(29%)。以上总总原因促成集团中台湾Buyippee买+易和台湾乐天市场首次合作讲座案。',
    'text3': 
      '这次讲座很荣幸和台湾乐天市场首次合作，其中我们邀请了约80位卖家来参与，在讲座的前半部主要分享除了工作之余如何帮自己加薪以及代购卖家养成计画，更重要的是Buyippee更推出出席讲座便享有5公斤跨国运费免费试用体验!',
    'text4': 
      '讲座上半场的内容主要分成四个部分分别为全球跨境电商趋势分析、分享代购卖家经营手法、传授海外商品选务秘笈以及轻松了解进口关税运费；下半场的内容则着重于乐天全球及在地化经营策略、全球四大电商平台模式解析以及百万店铺必备三大电商规划。由于此次为台湾Buyippee首次讲座，在会后，有许多感兴趣的卖家和从来没有使用过代运代购服务的朋友对于不同地区的服务费、运费、关税….等等都想有更进一步的了解和咨询，Buyippee团队也一一为回答所有问题。藉由这次的讲座也让更多人有机会接触Buyippee的服务，也是台湾团队跨出的一大步。',
    'text5': 
      'Buyippee团队是你们最专业的顾问，我们提供代购代运双服务，在业界拥有最多海外地址，并且港台团队同步经营，不必烦恼进口清关手续!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <img src={ img2 } alt="img2"/>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <img src={ img3 } alt="img3"/>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <img src={ img4 } alt="img4"/>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
      <img src={ img5 } alt="img5"/>
      <p>
        { langTW && text_tw.text5 }
        { langCN && text_cn.text5 }
      </p>
      <a href="http://www.buyippee.com.tw/" target="_blank" rel="noreferrer">http://www.buyippee.com.tw/</a>
    </>
  )
}

export default Blog1;