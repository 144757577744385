import React from "react";
import "../styles/NavbarComp.css";

const NavbarComp = ({ menu }) => {
  return (
    <div className="navBar">
      <div className="wrap">
        { menu }
      </div>
    </div>
  );
};

export default NavbarComp;