module.exports = [
  {
    "key": 1,
    "title_tw": "2018唯高盛集團台灣分公司年終歲末尾牙",
    "title_cn": "2018唯高盛集团台湾分公司年终岁末尾牙",
    "title_en": "2018 CBES Group TW Annual Dinner",
    "time": "2019-01-25",
    "month": "1月",
    "month_en": "JAN",
    "date": "25",
    "description_tw": 
      "2018唯高盛集團台灣分公司年終歲末尾牙圓滿結束，謝謝各位同...",
    "description_cn": 
      "2018唯高盛集团台湾分公司年终岁末尾牙圆满结束，谢谢各位同...",
    "description_en": 
      "2018 CBES Group Taiwan Annual Dinner was rounded out, thank you for supporting and participating in the event. Our Taiwan branch Annual Dinner was held on Friday, January 24, 2019...",
    "image": require("../img/News_list_01.jpg")
  },
  {
    "key": 2,
    "title_tw": "2019年2月份Continental全球假期公告",
    "title_cn": "2019年2月份Continental全球假期公告",
    "title_en": "2019 February Global Holiday Notice",
    "time": "2019-01-29",
    "month": "1月",
    "month_en": "JAN",
    "date": "29",
    "description_tw": 
      "感謝在過去一直使用我們的物流服務，Continental全體...",
    "description_cn": 
      "感谢在过去一直使用我们的物流服务，Continental全体...",
    "description_en": 
      "Thank you for choosing us as your logistic partner, we can’t be here without all of you. Good luck and great success on E-Commerce in the coming Lunar New Year!",
    "image": require("../img/News_list_02.jpg")
  },
  {
    "key": 3,
    "title_tw": "唯高盛集團有限公司成立新聞發布會",
    "title_cn": "唯高盛集团有限公司成立新闻发布会",
    "title_en": "CBES Group Establishment Press Conference",
    "time": "2019-01-31",
    "month": "1月",
    "month_en": "JAN",
    "date": "31",
    "description_tw": 
      "集團創辦人暨行政總裁李基銓先生 (Mr. Stanley L...",
    "description_cn": 
      "集团创办人暨行政总裁李基铨先生 (Mr. Stanley L...",
    "description_en": 
      "Our founder and CEO Mr. Stanley Lee will illustrate how our one and only multidimensional game changing solution would be helping the industry to explore the global perspective of e-commerce...",
    "image": require("../img/News_list_03.jpg")
  },
  {
    "key": 4,
    "title_tw": "倒數一個月! 我們準備好了!",
    "title_cn": "倒数一个月! 我们准备好了!",
    "title_en": "1 Month Countdown and We Are Ready!",
    "time": "2019-02-01",
    "month": "2月",
    "month_en": "FEB",
    "date": "01",
    "description_tw": 
      "在2019年2月公司即將展開新一章，唯高盛集團集結來自不同部...",
    "description_cn": 
      "在2019年2月公司即将展开新一章，唯高盛集团集结来自不同部...",
    "description_en": 
      "Our team of young and passionate talents are excited about the very big next step of CBES in Feb 2019! Leaders from different business units are coming together as one,...",
    "image": require("../img/News_list_04.jpg")
  },
  {
    "key": 5,
    "title_tw": "唯高盛有限公司新聞發布會",
    "title_cn": "唯高盛有限公司新闻发布会",
    "title_en": "CBES Group Establishment Press Conference",
    "time": "2019-03-11",
    "month": "3月",
    "month_en": "MAR",
    "date": "11",
    "description_tw": 
      "唯高盛集團於2月28日正式成立！感謝各位來賓的參與，見證香港...",
    "description_cn": 
      "唯高盛集团于2月28日正式成立！感谢各位来宾的参与，见证香港...",
    "description_en": 
      "Cross Border E-commerce Service (CBES) Group is established on 28 Feburary 2019!",
    "image": require("../img/News_list_05.jpg")
  },
  {
    "key": 6,
    "title_tw": "IDS集團成立二十週年慶祝晚宴",
    "title_cn": "IDS集团成立二十周年庆祝晚宴",
    "title_en": "IDS Hong Kong Company 20th Anniversary Dinner",
    "time": "2019-03-11",
    "month": "3月",
    "month_en": "MAR",
    "date": "11",
    "description_tw": 
      "IDS集團不經不覺已成立二十週年，當晚香港員工、海外代表和合...",
    "description_cn": 
      "IDS集团不经不觉已成立二十周年，当晚香港员工、海外代表和合...",
    "description_en": 
      "IDS Hong Kong Company has reached the 20th Anniversary of establishment! Teams from Hong Kong, overseas and our strategic partners came together to celebrate the major achievements of the company...",
    "image": require("../img/News_list_06.jpg")
  },
  {
    "key": 7,
    "title_tw": "《Capital Weekly》工商新知 – 唯高盛集團致力協助發展跨境電貿",
    "title_cn": "《Capital Weekly》工商新知 – 唯高盛集团致力协助发展跨境电贸",
    "title_en": "《Capital Weekly》 CBES Group Dedicates to Assisting in The Development of Cross Border E-Commerce Business",
    "time": "2019-03-13",
    "month": "3月",
    "month_en": "MAR",
    "date": "13",
    "description_tw": 
      "非常感謝Capital Weekly的報導! 就像文章內說的...",
    "description_cn": 
      "非常感谢Capital Weekly的报导! 就像文章内说的...",
    "description_en": 
      "Much appreciated Capital Weekly’s report. As the article said, the global E-Commerce is vigorously developing, but many manufacturers, brands, service providers, and online auctions in Hong Kong are still facing...",
    "image": require("../img/News_list_07.jpg")
  },
  {
    "key": 8,
    "title_tw": "第5屆台灣智慧零售暨電子商務展 活動精彩落幕",
    "title_cn": "第5届台湾智慧零售暨电子商务展 活动精彩落幕",
    "title_en": "The 5th Discover Advanced Trends in E-Commerce Exhibition (DATE Expo) has come to the end successfully",
    "time": "2019-06-06",
    "month": "6月",
    "month_en": "JUN",
    "date": "06",
    "description_tw": 
      "第5屆台灣智慧零售暨電子商務展5/31日順利於世貿一館落幕。...",
    "description_cn": 
      "第5届台湾智慧零售暨电子商务展5/31日顺利于世贸一馆落幕。...",
    "description_en": 
      "The 5th Discover Advanced Trends in E-Commerce Exhibition (DATE Expo) has came to the end successfully at May 31st. During the Discover Advanced Trends in E-commerce 2019, CBES Group CEO,...",
    "image": require("../img/News_list_08.jpg")
  },
  {
    "key": 9,
    "title_tw": "Continental正式宣布與UPS攜手合作，為台灣賣家提供全球運輸方案",
    "title_cn": "Continental正式宣布与UPS携手合作，为台湾卖家提供全球运输方案",
    "title_en": "Continental and UPS team up, offering integrated global shipping solutions for Taiwanese sellers.",
    "time": "2023-08-01",
    "month": "8月",
    "month_en": "AUG",
    "date": "01",
    "description_tw":
        "我們很榮幸地宣布，Continental和UPS已正式成為合作夥伴",
    "description_cn":
        "我们很荣幸地宣布，Continental和UPS已正式成为合作伙伴",
    "description_en":
        "We are delighted to announce the collaboration between Continental and UPS,",
    "image": require("../img/News_list_09.jpg")
  },
]