import React from 'react';
import img1 from '../../img/Blog_img_9_1.jpg';
import img2 from '../../img/Blog_img_9_2.jpg';
import img3 from '../../img/Blog_img_9_3.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const Blog9 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  }

  const text_tw = {
    'text1': 
      '握有全球電商的龍頭寶座的亞馬遜計畫在7月中旬關閉在中國電商平台，退出擁有阿里巴巴和京東兩大知名電商的中國市場，此次亞馬遜中國市場決策的調整僅涉及電子商務的業務，中國的消費者仍然可以購買Kindle 電子書，使用亞馬遜雲端服務AWS以及使用全球性的電商平台，訂購美國、英國、日本等海外產品。',
    'text2': 
      '早在2004年亞馬遜就進入中國市場，當時以7500萬美元收購中國電商平台卓越，並於2011年改名為亞馬遜中國。起初亞馬遜在中國市場就面臨不小的競爭壓力，但亞馬遜最大的優勢在於平台聚集大量海外的零售公司。但隨著阿里巴巴旗下的淘寶和天貓兩大平台隨後也開放海外市場加入，亞馬遜在中國擁有的優勢就此消失，除此之外，亞馬遜出貨也難以和天貓、京東相比。根據市場研究公司艾瑞顯示，阿里巴巴和京東就佔據超過80%的中國電商市場。',
    'text3': 
      '隨著亞馬遜退出中國市場，除了顯示在中國發展的困境外，也顯示出中國電商市場早已經接近飽和，關於阿里巴巴一月份財務報告中，儘管季度成長41%，卻是近年來成長幅度最低的一季；至於京東更是屢傳裁員好來人力降低成本。雖是退出中國，亞馬遜在中國其他領域依舊保有相當的競爭力，像是Kindle電子書自2013年進入中國市場，起初並未被看好，三年後中國為Kindle業務的第一大市場。目前Kindle在中國的市佔率更高達65%。',
    'text4': 
      '最後，許多專家認為亞馬遜選擇調整中國市場的業務是想將金流轉向印度的一個象徵，不僅僅是因為印度是擁有世界第二多人口的國家，在印度，亞馬遜更在這以極速的方式成長，佔據超過30%電商市場，也成功獲得不少金流!',
  };

  const text_cn = {
    'text1': 
      '握有全球电商的龙头宝座的亚马逊计画在7月中旬关闭在中国电商平台，退出拥有阿里巴巴和京东两大知名电商的中国市场，此次亚马逊中国市场决策的调整仅涉及电子商务的业务，中国的消费者仍然可以购买Kindle 电子书，使用亚马逊云端服务AWS以及使用全球性的电商平台，订购美国、英国、日本等海外产品。',
    'text2': 
      '早在2004年亚马逊就进入中国市场，当时以7500万美元收购中国电商平台卓越，并于2011年改名为亚马逊中国。起初亚马逊在中国市场就面临不小的竞争压力，但亚马逊最大的优势在于平台聚集大量海外的零售公司。但随着阿里巴巴旗下的淘宝和天猫两大平台随后也开放海外市场加入，亚马逊在中国拥有的优势就此消失，除此之外，亚马逊出货也难以和天猫、京东相比。根据市场研究公司艾瑞显示，阿里巴巴和京东就占据超过80%的中国电商市场。',
    'text3': 
      '随着亚马逊退出中国市场，除了显示在中国发展的困境外，也显示出中国电商市场早已经接近饱和，关于阿里巴巴一月份财务报告中，尽管季度成长41%，却是近年来成长幅度最低的一季；至于京东更是屡传裁员好来人力降低成本。虽是退出中国，亚马逊在中国其他领域依旧保有相当的竞争力，像是Kindle电子书自2013年进入中国市场，起初并未被看好，三年后中国为Kindle业务的第一大市场。目前Kindle在中国的市占率更高达65%。',
    'text4': 
      '最后，许多专家认为亚马逊选择调整中国市场的业务是想将金流转向印度的一个象征，不仅仅是因为印度是拥有世界第二多人口的国家，在印度，亚马逊更在这以极速的方式成长，占据超过30%电商市场，也成功获得不少金流!',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
      </p>
      <p>
        { langTW && text_tw.text2 }
        { langCN && text_cn.text2 }
      </p>
      <p>
        { langTW && text_tw.text3 }
        { langCN && text_cn.text3 }
      </p>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img2 } alt="img2"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <img src={ img3 } alt="img3"/>
        </Col>
      </Row>
      <p>
        { langTW && text_tw.text4 }
        { langCN && text_cn.text4 }
      </p>
    </>
  )
}

export default Blog9;