import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../img/News_img_8_1.jpg';
import img2 from '../../img/News_img_8_2.jpg';
import img3 from '../../img/News_img_8_3.jpg';
import img4 from '../../img/News_img_8_4.jpg';
import img5 from '../../img/News_img_8_5.jpg';
import img6 from '../../img/News_img_8_6.jpg';
import img7 from '../../img/News_img_8_7.jpg';
import img8 from '../../img/News_img_8_8.jpg';
import img9 from '../../img/News_img_8_9.jpg';
import img10 from '../../img/News_img_8_10.jpg';
import img11 from '../../img/News_img_8_11.jpg';
import img12 from '../../img/News_img_8_12.jpg';
import img13 from '../../img/News_img_8_13.jpg';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

const News6 = () => {
  const { i18n } = useTranslation();

  let langTW, langCN, langEN;
  if(i18n.language === 'zh-TW'){
    langTW = true;
  } else if(i18n.language === 'zh-CN'){
    langCN = true;
  } else {
    langEN = true;
  }

  const text_tw = {
    'text1': 
      '第5屆台灣智慧零售暨電子商務展5/31日順利於世貿一館落幕。其中展覽期間，唯高盛總裁李基銓更和現場民眾分享「跨境電商銷售攻略」獲得不少迴響。展覽3天共吸引國內外4,593人參觀以及2,683業界主管和電商專業人士，顯示台灣電商還是持續受到不少關注。',
  };

  const text_cn = {
    'text1': 
      '第5届台湾智慧零售暨电子商务展5/31日顺利于世贸一馆落幕。其中展览期间，唯高盛总裁李基铨更和现场民众分享「跨境电商销售攻略」获得不少回响。展览3天共吸引国内外4,593人参观以及2,683业界主管和电商专业人士，显示台湾电商还是持续受到不少关注。',
  };

  const text_en = {
    'text1': 
      'The 5th Discover Advanced Trends in E-Commerce Exhibition (DATE Expo) has came to the end successfully at May 31st. During the Discover Advanced Trends in E-commerce 2019, CBES Group CEO, Stanley Lee, got strong repercussions from sharing cross border eCommerce sales strategy with the audience. The three days exhibition attracted 4593 people from all over the world and 2683 eCommerce supervisors and professionals. It showed that Taiwan eCommerce business still received much attention.',
  };

  return(
    <>
      <img src={ img1 } alt="img1"/>
      <p>
        { langTW && text_tw.text1 }
        { langCN && text_cn.text1 }
        { langEN && text_en.text1 }
      </p>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img2 } alt="img2"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img3 } alt="img3"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img4 } alt="img4"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img5 } alt="img5"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img6 } alt="img6"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img7 } alt="img7"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img8 } alt="img8"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img9 } alt="img9"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img10 } alt="img10"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img11 } alt="img11"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img12 } alt="img12"/>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <img src={ img13 } alt="img13"/>
        </Col>
      </Row>
    </>
  )
}

export default News6;